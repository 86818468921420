@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";

.pux-container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
	@include transition;
	max-width: 100%;
	width: 100%;
	//height: 100%;
	@include media(xl) {
		max-width: $max-container-width;
		padding-left: 20px;
		padding-right: 20px;
	}
	&:after {
		display: block;
		content: "";
		clear: both;
	}
}

.col-xs-five-grid {
	@include media(xs, xs) {
		@include flex(0 0 20%);
		max-width: 20%;
	}
}

.col-sm-five-grid {
	@include media(sm, sm) {
		@include flex(0 0 20%);
		max-width: 20%;
	}
}

.col-md-five-grid {
	@include media(md, md) {
		@include flex(0 0 20%);
		max-width: 20%;
	}
}

.col-lg-five-grid {
	@include media(lg, lg) {
		@include flex(0 0 20%);
		max-width: 20%;
	}
}

.col-xl-five-grid {
	@include media(xl, xl) {
		@include flex(0 0 20%);
		max-width: 20%;
	}
}

.flex-as-end {
	@include align-self(flex-end);
}

.flex-box {
	@include flex-block;
}

.jc-space {
	@include justify-content(space-between);
}

.ai-center {
	@include align-items(center);
}

.full-container {
	max-width: $max-page-width;
	margin-left: auto;
	margin-right: auto;
	background-color: $color-white;
}

.header-helper {
	background-color: $color-base;
	padding-top: $menu-height;
}

header {
	position: fixed;
	top: 0;
	right: 0;
	margin: 0 auto;
	z-index: 999;
	max-width: $max-page-width;
	border-bottom: 2px solid $color-line;
	background-color: transparent;
	width: calc(100% - 20px);
	@include media(lg) {
		width: 100%;
		right: 50%;
		@include translate(50%, 0);
		background-color: $color-black !important;
		border-bottom: 2px solid $color-line;
	}
	.header {
		@include flex-block;
		@include justify-content(space-between);
		height: $menu-height;
		&-left {
			@include flex-block;
			@include align-items(center);
			@include justify-content(space-between);
			@include flex(0 0 50%);
			max-width: 50%;
			@include media(lg) {
				@include flex(0 0 170px);
				max-width: 170px;
				@include justify-content(flex-start);
			}
			&-logo {
				img {
					width: 25px;
					height: 32px;
				}
			}
			&-language {
				font-size: 0;
				margin: 0 2px;
				margin-left: 10px;
				cursor: pointer;
				@include flex-block;
				@include align-items(center);
				transform: translateX(50%);
				@include media(lg) {
					margin: 0 8px;
					@include font-size(16px);
					text-align: left;
					transform: none;
				}
				a {
					color: $color-white;
					margin-left: 10px;
					font-weight: 800;
					margin-top: 1px;
					&:before {
						content: "\e905";
						@include font-size(22px);
						line-height: 32px;
						color: $color-white;
						@include media(lg) {
							margin: 0 0 0 5px;
							vertical-align: -4px;
							line-height: normal;
						}
					}
				}
			}
		}
		&-right {
			$mobile-width: 225px;

			overflow: hidden;
			position: fixed;
			top: 0;
			right: 0;
			background-color: $color-primary;
			@include transition;
			transition-timing-function: ease-out;
			transform: translate3d(100%, 0, 0);
			height: 100vh;
			width: $mobile-width;
			padding: 105px 0px 25px 25px;
			overflow-x: visible;
			overflow-y: hidden;
			@include media(lg) {
				transform: translate3d(0, 0, 0);
				height: auto;
				width: auto;
				padding: 0;
				overflow: visible;
			}
			&.open {
				transform: translate3d(0, 0, 0);
			}
			@include media(lg) {
				position: static;
				background-color: transparent;
			}
			&-menu {
				max-height: calc(100vh - 130px);
				overflow-x: visible;
				overflow-y: auto;
				padding-right: 25px;
				position: relative;
				@include media(lg) {
					max-height: none;
					padding: 0;
					overflow: visible;
				}
				#menuElem {
					@include flex-block;
					@include flex-flow(column);
					padding: 0;
					margin: 0;
					list-style: none;
					overflow: hidden;
					@include media(lg) {
						@include flex-flow(row);
						overflow: visible;
					}
					& > li {
						position: relative;
						@include media(lg) {
							padding-left: 44px;
						}
						& > a {
							@include flex-inline;
							@include align-items(center);
							color: $color-white;
							font-family: "Zirkel-Bold";
							font-weight: 700;
							@include font-size(20px);
							position: relative;
							padding-bottom: 20px;
							@include transition;
							@include media(lg) {
								height: $menu-height;
								padding-bottom: 0;
								@include font-size(18px);
							}
							&:after {
								@include media(lg) {
									content: "";
									position: absolute;
									bottom: 0;
									left: 0;
									width: 100%;
									height: 4px;
									background-color: $color-link;
									@include transition;
									transition-timing-function: ease-out;
									opacity: 0;
									transform: translateY(100%);
								}
							}
							&:hover,
							&:focus {
								@include media(xxs, md) {
									color: $color-black;
								}
								&:after {
									opacity: 1;
									transform: translateY(0%);
								}
							}
						}
						ul {
							display: none;
							list-style-type: none;
							padding-left: 25px;
							@include media(lg) {
								border-top: 2px solid $color-line;
								background-color: rgba($color-line, 0.93);
								padding: 10px 25px;
								padding-right: 0;
								position: absolute;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translate(-25px, 100%);
								columns: 2;
								column-gap: 25px;
								display: none !important;
							}
							li {
								padding: 0;
								margin: 0;
								min-width: 125px;
								@include media(lg) {
									min-width: 125px;
								}
								a {
									color: $color-white;
									font-family: "Zirkel-Black";
									font-weight: 800;
									@include font-size(16px);
									position: relative;
									display: inline-block;
									padding-bottom: 20px;
									@include transition;
									@include media(lg) {
										padding-bottom: 3px;
										margin-bottom: 7px;
									}
									&:after {
										@include media(lg) {
											content: "";
											position: absolute;
											bottom: 0;
											left: 0;
											width: 100%;
											height: 2px;
											background-color: $color-link;
											@include transition;
											transition-timing-function: ease-out;
											opacity: 0;
										}
									}
									&:hover,
									&:focus {
										@include media(xxs, md) {
											color: $color-black;
										}
										&:after {
											opacity: 1;
										}
									}
								}
							}
						}
						&:hover,
						&:focus {
							ul {
								@include media(lg) {
									display: inline-block !important;
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
		&-right-mobile-menu {
			height: $menu-height;
			@include flex-block;
			@include align-items(center);
			@include media(lg) {
				display: none;
			}
			&-inner {
				position: relative;
				cursor: pointer;
				width: 26px;
				height: 30px;
				@include flex-block;
				@include align-items(center);
				span {
					width: 26px;
					position: absolute;
					top: 50%;
					margin-top: -1px;
					background: $color-white;
					height: 2px;
					left: 50%;
					margin-left: -13px;
					@include transition;
					&:before,
					&:after {
						content: "";
						width: 26px;
						height: 2px;
						position: absolute;
						display: block;
						background: $color-white;
						@include transition;
					}
					&:before {
						top: -8px;
					}
					&:after {
						bottom: -8px;
					}
				}
				@include media(lg) {
					display: none !important;
				}
				&.open {
					span {
						background: transparent;
						&:before {
							@include rotate(45deg);
							top: 0;
						}
						&:after {
							@include rotate(-45deg);
							top: 0;
						}
					}
				}
			}
		}
	}
}

footer {
	max-width: $max-page-width;
	margin: auto;
	background: $color-black;
	color: $color-footer-text;
	padding: 20px 0;
	margin-top: 0;
	@include font-size-with-line(14px);
	@include media(lg) {
		padding: 35px 0;
	}
	a {
		color: $color-footer-text;
		&:hover,
		&:focus {
			text-decoration: underline;
			color: $color-footer-text;
		}
	}
	.footer {
		&-bottom {
			padding-top: 50px;
			@include media(lg) {
				border-top: 2px solid $color-gray-dark;
				padding: 0;
				margin-top: 50px;
				height: 95px;
				@include flex-block;
				@include justify-content(space-between);
			}
			&-left {
				display: none;
				@include media(lg) {
					@include flex-block;
					@include align-items(flex-end);
				}
			}
			&-right {
				@include media(lg) {
					@include flex-block;
					@include align-items(flex-end);
				}
			}
		}
	}
	.nwl-title {
		//margin-bottom: 20px;
		margin-top: 40px;
		@include media(lg) {
			margin-top: 0;
		}
	}
	.nwl-box {
		label {
			display: none;
		}
		.form-horizontal {
			@include flex-block;
			@include align-items(flex-start);
			@include flex-direction(column);
			@include media(md) {
				@include flex-direction(row);
			}
			.form-group {
				width: 100%;
				@include media(md) {
					width: auto;
				}
			}
		}
		input[type="text"] {
			width: 100%;
			margin-bottom: 0;
			padding: 0 5px;
			height: 35px;
			line-height: 24px;
			font-size: 14px;
			color: $color-footer-text;
			background: transparent;
			border: 2px solid $color-gray-darken;
			@include border-radius(1px);
			@include animate(border-color);

			&:focus {
				border-color: $color-white;
			}

			&:-moz-placeholder {
				color: #fff;
			}

			&::-moz-placeholder {
				color: #fff;
			}

			&::-webkit-input-placeholder {
				color: #fff;
			}

			&:-ms-input-placeholder {
				color: #fff;
			}
			@include media(md) {
				width: 200px;
			}
		}
		input[type="submit"] {
			cursor: pointer;
			display: block;
			float: right;
			width: 100%;
			margin-left: 10px;
			font-size: 14px;
			height: 35px;
			outline: none;
			text-align: center;
			text-decoration: none;
			padding: 0 !important;
			background: transparent;
			border: 2px solid $color-gray-darken;
			color: $color-white;
			border-radius: 1px;
			font-weight: bold;
			font-family: "Zirkel-Bold";
			@include animate("background, border-color, color");

			&:hover,
			&.active {
				border-color: #7a7a7a;
				color: $color-white;
				background: #7a7a7a;
			}
			@include media(md) {
				width: 85px;
			}
		}
	}
	.nwl-bottom {
		@include flex-block;
		@include flex-direction(column);
		.copyright {
			padding-bottom: 10px;
			@include font-size-with-line(10px);
			@include media(md) {
				padding-right: 10px;
			}
			@include media(lg) {
				padding-right: 85px;
				padding-bottom: 0;
			}
		}
		@include media(md) {
			@include align-items(center);
			@include flex-direction(row);
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			vertical-align: top;
			margin: 0 5px 0 -5px;
			@include font-size-with-line(10px);
			a {
				display: block;
				padding: 5px;
				color: $color-white;
				text-decoration: none;

				&:hover {
					color: $color-white;
					text-decoration: underline;
				}
			}
		}
	}
}

.footer-contact {
	min-height: 120px;
	position: relative;
	@include font-size(14px);
	max-width: 273px;
	&-inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		&-image {
			position: relative;
			top: 12px;
			@include media(md) {
				top: 16px;
			}
			img {
				max-width: 100%;
				height: 100px;
				@include media(md) {
					height: 130px;
				}
			}
		}
		&-content {
			background-color: $color-primary;
			color: $color-white;
			@include font-size(16px);
			font-family: "Zirkel-Black";
			font-weight: 800;
			padding: 30px 30px 30px;
		}
	}
}

.footer-logo-small-device {
	margin-top: 40px;
	padding-top: 40px;
	border-top: 2px solid $color-gray-dark;
	padding-bottom: 20px;
	@include media(md) {
		display: none;
	}
}

.footer-social {
	font-size: 0;
	.ico-social {
		display: inline-block;
		//background:#fff;
		@include border-radius(50%);
		margin: 5px;
		height: 27px;
		width: 27px;
		text-decoration: none !important;
		position: relative;
		&:before {
			color: #fff;
			@include animate(color);
			@include font-size(27px);
			position: absolute;
			top: 0;
			left: 0;
		}
		&:focus,
		&:hover {
			&:before {
				color: $color-link;
			}
		}
		&:first-child {
			margin: 5px 5px 5px 0;
			@include media(md) {
				margin: 5px;
			}
		}
		@each $name, $icon-number in $share-icon-names {
			&.s-#{$name} {
				&:before {
					content: "#{$icon-number}";
				}
			}
		}
	}
}

.bottom-logo {
	img {
		width: 70px;
		height: auto;
		@include media(lg) {
			width: 120px;
		}
	}
	&-text {
		margin-top: 5px;
		@include font-size(8px);
		font-weight: 300;
		line-height: normal;
		margin-bottom: 20px;
		@include media(lg) {
			@include font-size(10px);
		}
	}
}

.block-medium {
	margin-top: 30px;
	margin-bottom: 30px;
	@include media(md) {
		margin-top: 35px;
		margin-bottom: 35px;
	}
	@include media(lg) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

.block-large {
	margin-top: 20px;
	margin-bottom: 20px;
	@include media(sm) {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	@include media(md) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
	@include media(lg) {
		margin-top: 60px;
		margin-bottom: 60px;
	}
	@include media(xxl) {
		margin-top: 70px;
		margin-bottom: 70px;
	}
}

.block-map {
	margin: 40px 0;
	@include media(lg) {
		margin-top: 35px;
		margin-bottom: 60px;
	}
}

.items-container {
	@include flex-block();
	@include flex-wrap(wrap);
}

.page-info {
	background-color: $color-black;
	color: $color-white;
	padding-bottom: 45px;
	&-bredcrumbs {
		margin-top: 20px;
		margin-bottom: 30px;
		color: $color-white;
		@include font-size(14px);
		font-weight: 700;
		a {
			color: $color-white;
			@include font-size(14px);
		}
	}
	&-text {
		@include flex-block;
		@include flex-flow(column);
		@include media(md) {
			@include flex-flow(row);
		}
		&-name {
			margin-top: 20px;
			@include media(md) {
				@include flex(0 1 250px);
				max-width: 250px;
				margin-top: 40px;
			}
			@include media(xl) {
				@include flex(0 1 350px);
				max-width: 350px;
				margin-top: 40px;
			}
		}
		&-description {
			order: -1;
			@include font-size(18px);
			margin-top: 30px;
			line-height: 1.4;
			@include media(md) {
				order: 2;
				@include font-size(20px);
				@include flex(0 1 auto);
				max-width: calc(100% - 250px);
				margin-top: 40px;
			}
			@include media(xl) {
				max-width: calc(100% - 350px);
			}
		}
	}
}

.header-no-menu {
	.page-info-text-name {
		@include media(md) {
			@include flex(0 1 50%);
			max-width: 50%;
			margin-top: 0;
		}
	}
	.page-info-text-description {
		@include font-size(18px);
		margin-top: 40px;
		line-height: 1.5;
		order: 2;
		@include media(md) {
			@include font-size(18px);
			@include flex(0 1 50%);
			max-width: 50%;
			margin-top: 0;
		}
	}
}

.our-brands-padding {
	.page-info-text-description {
		@include media(md) {
			padding-bottom: 150px;
		}
		@include media(xl) {
			padding-bottom: 170px;
		}
	}
}

.base-container {
	width: 100%;
	@include media(md) {
		padding-left: 250px;
	}
	@include media(xl) {
		padding-left: 350px;
	}
}

.margin-y-20 {
	margin-top: 10px;
	margin-bottom: 10px;
	@include media(md) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.margin-bottom- {
	margin-top: 10px;
	margin-bottom: 10px;
	@include media(md) {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.margin-right-small {
	margin-right: 25px;
}

.base-padding {
	padding-left: 15px;
	padding-right: 15px;
	@include media(xl) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.mt-20 {
	margin-top: 20px;
}

.base-margin-top {
	margin-top: 20px;
	@include media(xxl) {
		margin-top: 30px;
	}
}

.full-height {
	height: 100%;
}

.block-search {
	padding-top: 30px;
	padding-bottom: 30px;
	@include media(md) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.map-area-image {
	position: relative;
	.language-area-image {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 33;
	}
	img {
		max-width: 100%;
	}
}

.modal-language-country {
	color: #fff !important;
	display: inline-block;
	&:focus,
	&.hover {
		color: $color-primary !important;
	}
}

.menu-layer {
	&:before {
		content: "";
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba($color-black, 0.7);
		width: 100%;
		height: 100vh;
		opacity: 0;
		animation: menu-layer 150ms linear forwards;
		animation-delay: 100ms;
		z-index: 99;
		@include media(lg) {
			display: none !important;
		}
	}
}

@keyframes menu-layer {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.page-content-padding {
	padding-top: 20px;
	@include media(md) {
		padding-top: 40px;
	}
}

.space-10 {
	$space-base: 10px;

	.row,
	.items-container {
		margin-left: -$space-base;
		margin-right: -$space-base;

		[class*="col-"] {
			padding-left: $space-base;
			padding-right: $space-base;
		}
	}
}

.padding-box {
	padding: 30px 23px;
	@include media(md) {
		padding: 30px 35px;
	}
}

.red-box {
	background-color: $color-primary;
	color: $color-white;
	margin-top: 20px;
	@include media(md) {
		margin-top: 40px;
	}
	@include media(lg) {
		margin-top: 75px;
	}
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5 {
		border-bottom: 1px solid $color-white !important;
	}
}

.grey-box {
	background-color: $color-gray;
	color: $color-black;
	margin-top: 20px;
	@include media(md) {
		margin-top: 30px;
	}
	@include media(lg) {
		margin-top: 40px;
	}
}

.form-box {
	max-width: 405px;
}

.margin-top-0 {
	margin-top: 0 !important;
}

.map-area-image {
	position: relative;
	.language-area-image {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 33;
	}
}

.modal-language-country {
	color: #fff !important;
	display: inline-block;
	&:focus,
	&:hover,
	&.hover {
		color: $color-primary !important;
	}
}

.map-area-image {
	display: none;
	@include media(sm) {
		display: block;
	}
}

.language-item {
	margin: 15px 0;
	@include media(md) {
		margin: 30px 0;
	}
	@include media(lg) {
		max-width: 20%;
		flex: 0 0 20%;
	}
	@include media(xxl) {
		flex: 0 1 16.666%;
		max-width: 16.666%;
	}
}

.bottom-margin-large {
	margin-bottom: 180px;
	@include media(md) {
		margin-bottom: 220px;
	}
}

.bottom-margin-large-md {
	margin-bottom: 180px;
	@include media(md) {
		margin-bottom: 0;
	}
}

.bg-home {
	width: 100%;
	@include media(lg) {
		background-image: url("/Direct/media/content/background-home.png");
		background-position: center top;
		background-size: auto 100%;
		background-repeat: no-repeat;
	}
	&.can-order {
		@include flex-block;
		@include flex-flow(column);
	}
	.order-to-first {
		order: -1;
	}
}

.browser-edge,
.IE11 {
	#menuElem ul {
		@include media(lg) {
			left: 0;
			transform: translate(10%, 100%) !important;
		}
	}
}
