$path-font: "../font/";

$color-black: #000;
$color-white: #fff;
$color-grey: #999;

$color-primary: #f73c37;
$color-secondary: #231f20;
$color-line: #999999;
$color-gray: #e1e1e1;
$color-red: #f73c37;
$color-gray-dark: #303030;
$color-gray-darken: #8e8e8e;
$color-footer-text: #fefefe;
$color-category-border: #d8d8d8;

$color-bg: #000000;
$color-hover: $color-primary;

$menu-height: 56px;

$color-base: #000;
$color-link: $color-primary;
$color-link-hover: darken($color-secondary, 8);

$color-border: #e0e0e0;
$color-inactive: #f0f0f0;

$font-size: 16px;
$max-page-width: 1442px;
$max-container-width: 1150px;

$font-base: Arial, Helvetica, sans-serif;

$transition-function: ease;
$transition-duration: 250ms;

$btn-bg: $color-white;
$btn-border: $color-black;
$btn-color: $color-black;

$btn-bg-hover: darken($btn-bg, 15%);
$btn-border-hover: darken($btn-border, 10%);

$btn-height: 40px;
$btn-border-width: 1px;

$input-light: #f2f2f2;
$input-dark: #c7c7c7;
$input-darken: #7a7a7a;

//arrays
$share-icon-names: (
	google: "\e915",
	tumblr: "\e916",
	facebook: "\e914",
	mail: "\e911",
	twitter: "\e912",
	linkedin: "\e910",
	vine: "\e913",
	youtube: "\e906",
);

$password-strength: (
	weak: #b22222,
	acceptable: #f4a460,
	average: #4169e1,
	strong: #3cb371,
	excellent: #228b22,
);

$colors: (
	mss: #ffd100,
	jte: #1f21ab,
	litec: #043a63,
	tomcat: #009641,
	xtruss: #f7403b,
	xstage: #f7403b,
	mobiltechlifts: #2c4390,
	exe: #424242,
	stagedex: #ff4d00,
	prolyft: #8cbf30
);

$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
