@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.product-category {
	margin-top: 30px;
	@include media(md) {
		margin-top: 35px;
	}
	@include media(lg) {
		margin-top: 40px;
	}
	ul {
		@include flex-block;
		@include flex-flow(row, wrap);
		list-style-type: none;
		padding: 0;
		margin: 0;
		li {
			@include flex(0 1 100%);
			max-width: 100%;
			@include transition;
			padding-bottom: 1px;
			@include media(sm) {
				@include flex(0 1 50%);
				max-width: 50%;
				padding-right: 1px;
			}
			@include media(md) {
				@include flex(0 1 33.3333%);
				max-width: 33.3333%;
			}
			@include media(lg) {
				@include flex(0 1 25%);
				max-width: 25%;
			}
			@include media(xl) {
				@include flex(0 1 20%);
				max-width: 20%;
			}
			a {
				width: 100%;
				height: 100%;
				background-color: #f1f0f1;
				display: block;
				color: $color-black;
				font-family: "Zirkel-Black";
				font-weight: 800;
				@include font-size(18px);
				min-height: 50px;
				padding: 10px 15px 10px 25px;
				@include flex-block;
				@include justify-content(space-between);
				@include align-items(center);
				@include transition;
				@include media(sm) {
					padding: 10px 10px 10px 20px;
				}
				&:after {
					content: "\e908";
					display: inline-block;
					@include font-size(26px);
					color: $color-black !important;
				}
				&:hover,
				&:focus,
				&.active {
					background-color: $color-primary;
					color: $color-black;
				}
			}
		}
	}
}

.line-gray {
	background-color: #dfdfdf;
	width: 100%;
	height: 2px;
	display: none;
	margin: 45px 0;
	@include media(md) {
		display: block;
	}
}

.product-filter {
	margin-top: 40px;
	@include flex-block;
	@include flex-flow(column);
	@include justify-content(space-between);
	@include media(md) {
		@include flex-flow(row, wrap);
	}
	@include media(lg) {
		margin-top: 50px;
	}
	&-items {
		@include flex-block;
		@include flex-flow(row, wrap);
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin: 10px 0;
			@include media(md) {
				margin: 20px 0;
			}
			a {
				margin-right: 30px;
				display: inline-block;
				cursor: pointer;
				&:before {
					display: block;
					@include font-size(22px);
					color: $color-black;
					@include transition();
					@include media(md) {
						@include font-size(26px);
					}
				}
				&.logo-litec {
					&:before {
						content: "\e91c";
					}
					&.active {
						&:before {
							color: #055694;
						}
					}
				}
				&.logo-mss {
					&:before {
						content: "\e91b";
					}
					&.active {
						&:before {
							color: #ffd100;
						}
					}
				}
				&.logo-jte {
					&:before {
						content: "\e923";
					}
					&.active {
						&:before {
							color: #1f21ab;
						}
					}
				}
				&.logo-flexa {
					&:before {
						content: "\e93f";
					}
					&.active {
						&:before {
							color: #000;
						}
					}
				}
				&.logo-tomcat {
					&:before {
						content: "\e91d";
					}
					&.active {
						&:before {
							color: #009641;
						}
					}
				}
				&.logo-xtruss {
					&:before {
						content: "\e927";
					}
					&.active {
						&:before {
							color: #f7403b;
						}
					}
				}
				&.logo-xstage {
					&:before {
						content: "\e926";
						@include font-size(25px);
						@include media(md) {
							@include font-size(29px);
						}
					}
					&.active {
						&:before {
							color: #f7403b;
						}
					}
				}
				&.logo-mobiltechlifts {
					&:before {
						content: "\e925";
					}
					&.active {
						&:before {
							color: #f7403b;
						}
					}
				}
				&.logo-exe {
					&:before {
						content: "\e924";
					}
					&.active {
						&:before {
							color: #f7403b;
						}
					}
				}
				&.logo-prolyte {
					&:before {
						content: "\e90b";
					}
					&.active {
						&:before {
							color: #44a1d6;
						}
					}
				}
				&.logo-stagedex {
					&:before {
						content: "\e93e";
						@include font-size(40px);
						transform: translateY(-4px);
						@include media(md) {
							@include font-size(45px);
							transform: translateY(-6px);
						}
					}
					&.active {
						&:before {
							color: #ff4d00;
						}
					}
				}
				&.logo-prolyft {
					&:before {
						content: "\e93d";
						@include font-size(40px);
						transform: translateY(-4px);
						@include media(md) {
							@include font-size(45px);
							transform: translateY(-6px);
						}
					}
					&.active {
						&:before {
							color: #8cbf30;
						}
					}
				}
			}
			&:last-of-type {
				a {
					margin-right: 0px;
				}
			}
		}
	}
}

.product-container {
	margin-top: 30px;
	@include media(md) {
		margin-top: 0;
	}
	.items-container {
		@include flex-block;
		@include flex-flow(row, wrap);
		margin-left: -15px;
		margin-right: -15px;
		[class*="col-"] {
			padding-left: 7px;
			padding-right: 7px;
		}
	}
}

.product {
	&-item {
		position: relative;
		display: block;
		padding: 5px;
		@include animate("background-color, color");
		color: $color-black !important;
		text-decoration: none !important;
		background-color: $color-gray;
		width: 100%;
		margin-bottom: 16px;
		@include flex-block;
		height: calc(100% - 16px);
		min-height: 162px;
		&:hover,
		&:focus {
			text-decoration: none !important;
			@include media(lg) {
				background-color: $color-primary;
			}

			.product-item-content {
				&:before {
					background-color: $color-black;
				}
				&:after {
					color: $color-primary;
				}
				&-button {
					@include media(lg) {
						color: $color-primary !important;
						background: $color-black !important;
						text-decoration: none !important;
					}
					&:hover,
					&:focus {
						color: $color-white !important;
					}
				}
			}
		}
		&-img {
			width: 100%;
			position: relative;
			text-align: center;
			@include flex(0 1 45%);
			max-width: 45%;
			background-color: $color-white;
			img {
				max-width: 100%;
				max-height: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				@include translate(-50%, -50%);
			}
		}
		&-content {
			position: relative;
			@include flex(0 1 55%);
			max-width: 55%;
			text-align: left;
			padding-left: 15px;
			padding-right: 20px;
			&-title {
				@include font-size(18px);
				border-bottom: 0px;
				text-decoration: none !important;
				line-height: 28px;
				background-image: linear-gradient(#858383 2px, transparent 2px);
				background-size: 100% 28px;
				background-position: 0 26px;
				font-family: "Zirkel-Black";
				font-weight: 800;
				padding-bottom: 2px;
				overflow: hidden;
				max-height: 56px;
				@include media(xs) {
					margin-top: 0px;
				}
			}
			&-perex {
				line-height: 1.3;
				@include font-size(14px);
				font-weight: 400;
			}
			&-button {
				padding: 4px 10px;
				margin-top: 15px;
				@include font-size(14px);
				background-color: $color-primary;
				color: $color-white;
				border-color: $color-primary;
				@include media(xs) {
					position: absolute;
					bottom: 0;
					right: 0;
				}
			}
			&:before {
				content: "";
				position: absolute;
				display: none;
				bottom: 2px;
				left: 17px;
				width: 27px;
				height: 27px;
				@include border-radius(27px);
				background-color: transparent;
				@include transition(100ms);
				@include media(lg) {
					display: block;
				}
			}
			&:after {
				content: "\e908";
				position: absolute;
				display: none;
				@include font-size(30px);
				@include transition(100ms);
				@include media(xs) {
					display: block;
					bottom: 0px;
					left: 5px;
				}
				@include media(md) {
					bottom: 0px;
					left: 15px;
				}
				@include media(sm, md) {
					color: $color-black !important;
				}
			}
		}
	}
}
