@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.top-banner {
	min-height: 745px;
	@include flex-block;
	@include flex-flow(column);
	color: $color-white;
	@include media(md) {
		@include flex-flow(row);
	}
	@include media(lg) {
		margin-top: $menu-height;
	}
	&-image {
		min-height: 100vh;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		@include flex-block;
		@include justify-content(center);
		padding-top: $menu-height;
		@include media(md) {
			@include justify-content(flex-end);
			@include flex(0 1 59%);
			max-width: 59%;
			min-height: 0;
		}
		@include media(lg) {
			padding: 0;
		}
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba($color-black, 0.5);
		}
		&-inner {
			width: 100%;
			position: relative;
			padding: 40px 30px;
			@include media(sm) {
				padding: 60px 30px;
			}
			@include media(md) {
				padding: 100px 30px;
			}
			@include media(md) {
				max-width: ($max-container-width * 0.59) + 40px;
				padding: 60px 30px;
			}
			@include media(xl) {
				padding: 80px 15px;
				padding-left: 48px;
			}
			@include media(xxl) {
				padding: 100px 0px;
				padding-left: 33px;
			}
			&-link {
				position: absolute;
				bottom: 32px;
				left: 30px;
				@include media(xl) {
					left: 48px;
				}
				@include media(xxl) {
					left: 33px;
				}
				a {
					color: $color-white;
					@include font-size(16px);
					@include transition;
					@include media(lg) {
						@include font-size(18px);
					}
					&:hover,
					&:focus {
						color: $color-primary;
					}
				}
			}
		}
	}
	&-content {
		background-color: $color-primary;
		@include flex-block;
		@include media(md) {
			@include flex(0 1 41%);
			max-width: 41%;
			padding-top: $menu-height;
		}
		@include media(lg) {
			padding: 0;
		}
		&-inner {
			width: 100%;
			padding: 40px 30px;
			@include media(md) {
				max-width: $max-container-width * 0.41;
				padding: 50px 40px;
				padding-right: 15%;
			}
			@include media(xxl) {
				padding: 70px 60px;
				padding-right: 15%;
			}
			&-title {
				@include font-size(18px);
				font-weight: 800;
				margin-bottom: 10px;
				letter-spacing: 1px;
				@include media(lg) {
					margin-bottom: 20px;
				}
			}
			&-name {
				margin-bottom: 0px;
				line-height: 1;
				letter-spacing: 1px;
				@include media(xxs, sm) {
					@include font-size(28px);
				}
				a {
					@include transition;
					display: inline-block;
					&:hover,
					&:focus {
						color: $color-black;
					}
				}
			}
		}
	}
}

.category {
	&-container {
		width: 100%;
		border-top: 2px solid $color-category-border;
		border-left: 2px solid $color-category-border;
		@include flex-block;
		@include flex-flow(row, wrap);
		@include media(md) {
			display: block;
		}
		&:after {
			clear: both;
			content: "";
			display: block;
		}
	}
	&-item {
		display: block;
		border-right: 2px solid $color-category-border;
		border-bottom: 2px solid $color-category-border;
		@include flex-block;
		@include flex-flow(column);
		background-color: $color-white;
		overflow: hidden;
		@include media(md) {
			float: left;
		}
		&-image {
			position: relative;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			@include flex(0 1 100%);
			overflow: hidden;
			@include transition(300ms);
			&:before {
				//content: "";
				//padding-top: 80%;
				//display: block;
			}
		}
		&-name {
			@include flex(0 1 40px);
			@include font-size(16px);
			font-weight: 800;
			position: relative;
			padding-bottom: 5px;
			background-color: $color-gray;
			padding: 4px 8px;
			@include transition;
			@include media(lg) {
				@include font-size(18px);
				@include flex(0 1 50px);
				padding: 6px 12px;
				padding-bottom: 10px;
			}
			&:before {
				//content: "";
				display: block;
				position: relative;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color-black;
			}
			&:after {
				content: "";
				display: block;
				position: relative;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				margin-top: 2px;
				background-color: $color-black;
				@include transition;
			}
		}
		&:hover,
		&:focus {
			.category-item-image {
				@include scale(1.1, 1.1);
			}
			.category-item-name {
				&:after {
					width: 100%;
					background-color: $color-primary;
				}
			}
		}
	}
	&-big-link {
		background-color: $color-primary;
		@include flex-block;
		height: 100%;
		@include transition;
		&-content {
			@include font-size(18px);
			color: $color-white;
			font-weight: 800;
			@include flex-block;
			@include flex-flow(column);
			@include justify-content(space-between);
			@include media(md) {
				@include flex(0 1 60%);
				max-width: 60%;
				padding: 10px 15px;
			}
			@include media(lg) {
				@include font-size(22px);
				padding: 20px 25px;
			}
			&-link {
				@include font-size(16px);
				font-weight: 300 !important;
				@include flex-block;
				@include align-items(center);
				@include media(lg) {
					@include font-size(18px);
				}
				&:after {
					content: "\e908";
					background: transparent;
					@include border-radius(50%);
					border: 1px solid $color-white;
					@include transition;
					width: 26px;
					height: 26px;
					@include font-size(24px);
					line-height: 26px;
					margin-left: 10px;
				}
			}
		}
		&-img {
			@include flex(0 1 40%);
			max-width: 40%;
			display: none;
			@include media(md) {
				display: block;
			}
			&-inner {
				@include flex-block;
				@include justify-content(center);
				@include align-items(flex-end);
				height: 100%;
				img {
					max-height: 100%;
					max-width: 100%;
					width: 100%;
				}
			}
		}
		&:hover,
		&:focus {
			background-color: darken($color-primary, 10);
		}
	}
}

.display-none-to-md {
	display: none;
	@include media(md) {
		display: block;
	}
}

.mnsr-item-1 {
	width: 50%;
	height: 193px;
	@include media(md) {
		width: 25%;
	}
	@include media(lg) {
		width: 20%;
	}
	@include media(xxl) {
		width: 16.66666666%;
	}
}

.mnsr-item-width-2 {
	width: 50%;
	@include media(lg) {
		width: 40%;
	}
	@include media(xxl) {
		width: 33.3333333%;
	}
}

.mnsr-item-height-2 {
	@include media(md) {
		height: 386px;
	}
}

.margin-top-container {
	margin-top: 15px;
	@include media(md) {
		margin-top: 20px;
	}
	@include media(xl) {
		margin-top: 25px;
	}
}

.service-card {
	@include flex-block;
	@include flex-flow(column);
	@include align-items(space-between);
	border-bottom: 2px solid $color-category-border;
	padding: 25px 20px 20px;
	@include transition;
	position: relative;
	@include media(md) {
		@include flex(0 1 33.3333%);
		max-width: 33.3333%;
		border: 2px solid $color-category-border;
	}
	@include media(lg) {
		padding: 35px 25px 25px;
	}
	&-title {
		@include font-size(40px);
		font-weight: 600;
		line-height: 1.1;
	}
	&-content {
		margin-top: 80px;
		@include font-size(18px);
		font-weight: 300;
		line-height: 1.3;
	}
	&:not(:first-of-type) {
		border-left: 0;
	}
	&.theme-black {
		background-color: $color-black;
		color: $color-white;
		&:hover,
		&:focus {
			color: $color-white;
		}
	}
	&.theme-gray {
		background-color: $color-gray;
	}
	&.theme-white {
		background-color: $color-white;
	}
	&-container {
		@include media(md) {
			@include flex-block;
		}
	}
}

.news-container {
	@include flex-block;
	@include flex-flow(row, wrap);
}
.news-item-hp {
	@include flex(0 1 100%);
	max-width: 100%;
	display: block;
	border: 1px solid #c7c7c7;
	background-color: $color-white;
	@include media(sm) {
		@include flex(0 1 50%);
		max-width: 50%;
	}
	@include media(lg) {
		@include flex(0 1 25%);
		max-width: 25%;
	}
	&:not(:first-of-type) {
		@include media(xxs, xl) {
			border-top: 0;
		}
		@include media(lg) {
			border-left: 0;
		}
	}
	&:nth-of-type(2n + 2) {
		@include media(sm, md) {
			border-left: 0;
		}
	}
	&-image {
		background-position: center !important;
		background-size: 100% auto !important;
		background-repeat: no-repeat !important;
		border-bottom: 10px solid $color-black;
		&:before {
			content: "";
			display: block;
			padding-top: 65%;
		}
	}
	&-content {
		padding: 15px 20px;
		@include font-size(14px);
		@include transition;
		@include media(md) {
			padding: 20px;
		}
		&-title {
			@include font-size(22px);
			font-weight: 800;
			border-bottom: 1px solid $color-black;
			padding-bottom: 5px;
			margin-bottom: 15px;
		}
	}
	@each $name, $color in $colors {
		&.color-#{$name} {
			.news-item-hp-image {
				position: relative;
				border-color: $color;
				&:after {
					content: "";
					position: absolute;
					display: block;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($color, 0.4);
				}
			}
		}
	}
}

.location-block {
	margin-top: 60px;
	margin-bottom: 180px;
	@include media(md) {
		margin-top: 90px;
		margin-bottom: 200px;
	}
	@include media(lg) {
		margin-top: 130px;
		margin-bottom: 212px;
	}
}

.locations {
	@include media(md) {
		@include flex-block;
	}
	&-content {
		background-color: $color-primary;
		padding: 50px 30px;
		color: $color-white;
		@include font-size(22px);
		font-weight: 600;
		line-height: 1.3;
		@include media(md) {
			@include flex(0 1 32.5%);
			max-width: 32.5%;
		}
		@include media(lg) {
			@include font-size(28px);
		}
	}
	&-map {
		height: 265px;
		@include media(md) {
			min-height: 440px;
			height: auto;
			@include flex(0 1 67.5%);
			max-width: 67.5%;
		}
	}
}

.map-points {
	display: none;
}
