@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.modal {
  background-color: rgba($color-black, .7);
  color: $color-black;
  padding-right: 0 !important;
  .modal-dialog {
    position: relative;
    @include flex-block();
    @include flex-direction(row);
    @include justify-content(center);
    margin: 10px auto;
    width: 100%;
    max-width: 1250px;
		@include media(md) {
				margin-top: 50px;
		}
		@include media(xxl) {
				margin-top: 100px;
		}
    .modal-content {
				border-radius: 0;
				background-color: #e1e1e1;
    }
  	.modal-header {

				.modal-title {
						font-weight: 800;
						@include font-size(20px);
						@include media(md) {
								@include font-size(24px);
						}
						@include media(lg) {
								@include font-size(28px);
						}
				}
        .close {
          color: $color-red;
					position: relative;
					font-size: 0;
					position: absolute;
					top: 15px;
					right: 15px;
					text-shadow: none;
					&:after {
							content: "\e921";
							@include icomoon();
							@include font-size(15px);
							display: inline-block;
							color: $color-primary;
							@include transition(100ms);
							cursor: pointer;
							color: $color-red;
							font-weight: 800;

					}
          &:hover,
          &:focus {
							&:after {
	            	color: darken($color-red, 10);
							}
          }
        }
    }
    .modal-body,
    .modal-footer {
    }

    .modal-body{
        text-align: left;
        .modal-perex {
          margin: 10px 0 30px;
          text-align: left;
          @include font-size(14px);
          line-height: 18px;
          font-weight: 600;
          @include media(sm) {
            margin-bottom: 30px;
          }
        }

			}
      .modal-footer {
        display: block;
        margin-top: 10px;

        .contact-submit-cont {
          width: 100%;
          margin: 0;

          .contact-submit-text {
            @include font-size(12px);
            color: #999;
            text-align: left;
            margin-bottom: 5px;

            @include media(sm) {
              margin-bottom: 10px;
            }

            @include media(md) {
              @include font-size(13px);
            }

            @include media(lg) {
              margin-bottom: 18px;
            }
          }

      }
    }
  }

}

.field-required {
  &:after {
    content: "*";
    color: #ff5445;
  }
}

.ErrorLabel,
.EditingFormErrorLabel {
		position: relative;
		display: block;
		width: 100%;
		margin: 20px 0px;
		padding: 5px 0;
		padding-left: 15px;
		background-color: $color-primary;

}
.EditingFormErrorLabel {
		margin-top: 5px;
}


#modal-languages {
  .modal-content {
    padding: 20px;
    background-color: $color-black;

    .modal-header {
      background-color: $color-black;

      .modal-title {
        @include font-size(22px);
        font-weight: 400;
        color: $color-white;

        @include media(md) {
          @include font-size(28px);
        }
      }
    }
  }
  .modal-language-country {
    h3 {
      @include font-size(16px);
      font-weight: 800;

      @include media(lg) {
        @include font-size(18px);
      }
    }

    h4 {
      @include font-size(14px);
      font-weight: normal;

      &:last-of-type {
        text-transform: uppercase;
      }
    }
  }
	.close {
			color: $color-white;
			@include media(xxs, sm) {
					top: 10px;
					right: 30px;
			}
			&:after {
					color: $color-white;
			}
	}
}

.InfoLabel {
		display: block;
		margin: 30px 0;
}

#get-quote {
		.modal-content {
				max-width: 500px;
				width: 100%;
		}
		.modal-body {
				padding-top: 0px;
		}
		.modal-header {
				padding-bottom: 0 !important;
		}
}
