@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.links-table {
	position: relative;
	border-top: 2px solid $color-white;
	margin-top: 25px;
	padding-top: 10px;
	@include media(lg) {
		display: block;
		margin-top: 30px;
		padding-top: 10px;
	}
	@include media(xl) {
		margin-top: 50px;
		padding-top: 30px;
	}
	.row {
		width: 100%;
	}
	&-item {
		height: 40px;
		position: relative;
		margin-top: 20px;
		@include media(md) {
			height: 70px;
			flex: none;
			margin-top: 0;
		}
		img {
			position: absolute;
			bottom: 50%;
			left: 0%;
			max-width: 100%;
			max-height: 100%;
			@include translate(0%, 50%);
			@include media(lg) {
				bottom: 0;
				@include translate(0%, 0);
			}
		}
	}
	&.menu-links {
		margin-top: 20px;
		padding-top: 40px;
		padding-right: 10px;
		border-top: 0;
		@include media(lg) {
			display: none;
		}
		&:before {
			content: "";
			top: 0;
			left: 0;
			position: absolute;
			width: calc(100% + 25px);
			height: 1px;
			background-color: $color-white;
		}
	}
}

.triangle-hover {
	position: relative;
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		@include triangle-right(50px, 50px, 50px, $color-primary);
		@include transition(350ms);
		transform: rotate(-45deg) translate(82px, -14px);
		z-index: 88;
	}
	&:after {
		content: "\e91f";
		position: absolute;
		top: 9px;
		right: 8px;
		font-weight: 900;
		@include font-size(26px);
		color: $color-white !important;
		opacity: 0;
		z-index: 99;
	}
	&:hover,
	&:focus {
		color: $color-black;
		&:before {
			transform: rotate(-45deg) translate(31px, -18px);
		}
		&:after {
			animation: hover-arrow 200ms ease-out forwards;
			animation-delay: 350ms;
		}
	}
}

@keyframes hover-arrow {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.brand-item {
	@include flex-block;
	@include flex-flow(row, wrap);
	min-height: 335px;
	background-color: yellow;
	margin-top: 20px;
	position: relative;
	padding: 20px;
	@include font-size(20px);
	line-height: 1.4;
	@include media(md) {
		margin-top: 35px;
		top: -190px;
	}
	@include media(xl) {
		margin-top: 55px;
		top: -210px;
	}
	&-img {
		@include flex(0 1 100%);
		max-width: 100%;
		padding-bottom: 40px;
		@include media(md) {
			@include flex(0 1 37.5%);
			max-width: 37.5%;
			padding-bottom: 70px;
		}
		img {
			max-height: 150px;
			max-width: 100%;
		}
	}
	&-content {
		@include flex(0 1 100%);
		max-width: 100%;
		padding-bottom: 70px;
		@include media(md) {
			@include flex(0 1 62.5%);
			max-width: 62.5%;
		}
	}
	&-bottom {
		@include flex(0 1 100%);
		max-width: 100%;
		align-self: flex-end;
		color: $color-white !important;
		&-link {
			color: $color-white !important;
			@include flex-inline;
			&:after {
				content: "\e907";
				display: block;
				@include font-size(32px);
				color: $color-white !important;
				font-weight: 600;
				margin-left: 10px;
				@include transition;
			}
			&:hover,
			&:focus {
				&:after {
					@include scale(0.8, 0.8);
				}
			}
		}
	}
	&.theme-light {
		color: $color-white;
	}
	&.theme-dark {
		color: $color-black;
	}
}

.header-menu {
	padding: 0;
	margin: 0;
	@include font-size(20px);
	list-style: none;
	font-weight: bold;
	@include media(md) {
		@include font-size(22px);
	}
	&.main {
		text-transform: uppercase;
	}
	li {
		padding: 0;
		margin: 0;
		line-height: 1.3;
		a {
			color: $color-white;
			@include transition;
			&:hover,
			&:focus {
				color: $color-primary;
			}
		}
	}
	&.with-margin {
		margin-top: 15px;
	}
}

.content-gallery {
	margin: 40px 0;
	@include flex-block();
	@include flex-flow();
	@include justify-content(space-between);
	position: relative;
	&-list {
		max-height: 100px;
		@include calc("width", "100% - 100px");
		overflow: hidden;
		.slick-track {
			@include flex-block;
		}
		&-item {
			width: auto;
			height: 100px;
			display: block;
			img {
				width: auto;
				height: 100px !important;
				margin-left: 50%;
				@include translate(-50%, 0);
				margin-top: 0 !important;
				margin-bottom: 0 !important;
			}
		}
	}
	&-arrow-left,
	&-arrow-right {
		width: 40px;
		@include font-size(0px);
		cursor: pointer;
		margin-right: 10px;
		@include unselectable();

		&:before {
			@include icomoon;
			@include pseudo-elements("\e90d", relative, 50%, 50%);
			display: block;
			@include translate(-50%, -50%);
			color: $color-black;
			@include font-size(40px);
			width: 40px;
			height: 40px;
			border-radius: 40px;
			border: 1px solid $color-black;
			@include transition(150ms);
			@include unselectable();
		}

		&:focus,
		&:hover {
			&:before {
				color: $color-white;
				background-color: $color-black;
			}
		}
	}

	&-arrow-right {
		margin-right: 0px;
		margin-left: 10px;

		&:before {
			content: "\e908";
		}
	}
}

.main-slider {
	width: 100%;
	position: relative;
	.slick-dots {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 15px;
		text-align: center;
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 4px;
			width: 8px;
			height: 8px;
			cursor: pointer;
			background: $color-white;
			@include border-radius(25px);
			@include animate();
			&:hover {
				background: $color-secondary;
			}
			button {
				display: none;
			}
			&.slick-active {
				background-color: $color-primary;
			}
		}
	}
}

.content-item {
	@include flex-block;
	@include justify-content(center);
	@include align-items(center);
	width: 100%;
	background-position: top center !important;
	background-repeat: no-repeat !important;
	@include background-size(cover);
	&-title {
		font-size: 35px;
		line-height: 40px;
		font-weight: 600;
		text-align: center;
		@include media(sm) {
			font-size: 40px;
			line-height: 45px;
		}
		@include media(md) {
			font-size: 55px;
			line-height: 60px;
		}
		@include media(lg) {
			font-size: 65px;
			line-height: 70px;
		}
		@include media(xl) {
			font-size: 70px;
			line-height: 75px;
		}
		@include media(xl) {
			font-size: 92px;
			line-height: 100px;
		}
	}
	&-inner {
		@include flex-block;
		@include justify-content(center);
		@include align-items(center);
		height: 300px;
		padding: 40px 30px;
		@include media(sm) {
			height: 375px;
		}
		@include media(md) {
			height: 450px;
		}
		@include media(lg) {
			height: 535px;
		}
	}
}

.banner-container {
	width: 100%;
	position: relative;
	@include flex-block;
}

.theme-text-black {
	color: $color-black;
}

.theme-text-white {
	color: $color-white;
}

.embed-video.small {
	height: 420px;
}

.embed-video.small-16 {
	height: 320px;
}

.embed-video {
	height: 640px;
	position: relative;
}

.embed-video.large-16 {
	height: 540px;
}

.embed-video iframe {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.embed-video-overflow.autoplay-1,
.embed-video-overflow.played {
	opacity: 0;
	visibility: hidden;
	@include transition;
}

.embed-video-overflow {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #252525;
	background-size: cover;
	background-position: center;
	cursor: pointer;
	&.in-popup {
		display: block;
	}
}

.embed-video-icon {
	display: block;
	height: 90px;
	width: 90px;
	border: 2px solid #fff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	@include transition;
}

.embed-video-icon:before {
	content: "";
	display: inline-block;
	font-size: 32px;
	width: 0;
	height: 0;
	@include triangle-right(20px, 20px, 30px, #fff);
	@include transition;
	top: 22px;
	left: 35px;
	position: absolute;
}

.submenu-btn {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 2px;
	right: 0px;
	@include flex-block;
	@include align-items(center);
	@include justify-content(center);
	cursor: pointer;
	@include media(lg) {
		display: none !important;
	}
	&:before {
		content: "\e91f";
		color: $color-white;
		@include font-size(24px);
		@include transition;
		@include rotate(90deg);
		font-weight: 800;
	}
	&.open {
		&:before {
			@include rotate(270deg);
		}
	}
}

.afi-gallery {
	display: flex;
	flex-wrap: wrap;
	&-item {
		padding-left: 1px;
		padding-bottom: 1px;
		flex: 0 1 100%;
		max-width: 100%;
		@include media(xs) {
			flex: 0 1 50%;
			max-width: 50%;
		}
		@include media(lg) {
			flex: 0 1 33.333%;
			max-width: 33.333%;
		}
		&-inner {
			position: relative;
			overflow: hidden;
			display: block;
			&:before {
				content: "";
				display: block;
				padding-top: 80%;
			}
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				min-width: 100%;
				min-height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
	&.large-space {
		.afi-gallery-item {
			padding-bottom: 40px;
		}
	}
}
