@charset "UTF-8";
@import url(../fonts/icofont/style.css);
.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 1200px) {
    .pux-container {
      max-width: 1150px;
      padding-left: 20px;
      padding-right: 20px; } }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }

@media (min-width: 480px) and (max-width: 544px) {
  .col-xs-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 545px) and (max-width: 767px) {
  .col-sm-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) and (max-width: 1399) {
  .col-xl-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

.flex-as-end {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end; }

.flex-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.jc-space {
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.ai-center {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.full-container {
  max-width: 1442px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff; }

.header-helper {
  background-color: #000;
  padding-top: 56px; }

header {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  max-width: 1442px;
  border-bottom: 2px solid #999999;
  background-color: transparent;
  width: calc(100% - 20px); }
  @media (min-width: 992px) {
    header {
      width: 100%;
      right: 50%;
      -ms-transform: translate(50%, 0);
      -webkit-transform: translate(50%, 0);
      transform: translate(50%, 0);
      background-color: #000 !important;
      border-bottom: 2px solid #999999; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: 56px; }
    header .header-left {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex: 0 0 50%;
      -moz-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      -webkit-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
      @media (min-width: 992px) {
        header .header-left {
          -webkit-flex: 0 0 170px;
          -moz-flex: 0 0 170px;
          -ms-flex: 0 0 170px;
          -webkit-flex: 0 0 170px;
          flex: 0 0 170px;
          max-width: 170px;
          -webkit-box-pack: flex-start;
          -moz-box-pack: flex-start;
          -ms-flex-pack: flex-start;
          -webkit-justify-content: flex-start;
          justify-content: flex-start; } }
      header .header-left-logo img {
        width: 25px;
        height: 32px; }
      header .header-left-language {
        font-size: 0;
        margin: 0 2px;
        margin-left: 10px;
        cursor: pointer;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        transform: translateX(50%); }
        @media (min-width: 992px) {
          header .header-left-language {
            margin: 0 8px;
            font-size: 16px;
            font-size: 1rem;
            text-align: left;
            transform: none; } }
        header .header-left-language a {
          color: #fff;
          margin-left: 10px;
          font-weight: 800;
          margin-top: 1px; }
          header .header-left-language a:before {
            content: "\e905";
            font-size: 22px;
            font-size: 1.375rem;
            line-height: 32px;
            color: #fff; }
            @media (min-width: 992px) {
              header .header-left-language a:before {
                margin: 0 0 0 5px;
                vertical-align: -4px;
                line-height: normal; } }
    header .header-right {
      overflow: hidden;
      position: fixed;
      top: 0;
      right: 0;
      background-color: #f73c37;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      transition-timing-function: ease-out;
      transform: translate3d(100%, 0, 0);
      height: 100vh;
      width: 225px;
      padding: 105px 0px 25px 25px;
      overflow-x: visible;
      overflow-y: hidden; }
      @media (min-width: 992px) {
        header .header-right {
          transform: translate3d(0, 0, 0);
          height: auto;
          width: auto;
          padding: 0;
          overflow: visible; } }
      header .header-right.open {
        transform: translate3d(0, 0, 0); }
      @media (min-width: 992px) {
        header .header-right {
          position: static;
          background-color: transparent; } }
      header .header-right-menu {
        max-height: calc(100vh - 130px);
        overflow-x: visible;
        overflow-y: auto;
        padding-right: 25px;
        position: relative; }
        @media (min-width: 992px) {
          header .header-right-menu {
            max-height: none;
            padding: 0;
            overflow: visible; } }
        header .header-right-menu #menuElem {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-flex-flow: column nowrap;
          -ms-flex-direction: column;
          -ms-flex-wrap: nowrap;
          -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
          padding: 0;
          margin: 0;
          list-style: none;
          overflow: hidden; }
          @media (min-width: 992px) {
            header .header-right-menu #menuElem {
              -moz-flex-flow: row nowrap;
              -ms-flex-direction: row;
              -ms-flex-wrap: nowrap;
              -webkit-flex-flow: row nowrap;
              flex-flow: row nowrap;
              overflow: visible; } }
          header .header-right-menu #menuElem > li {
            position: relative; }
            @media (min-width: 992px) {
              header .header-right-menu #menuElem > li {
                padding-left: 44px; } }
            header .header-right-menu #menuElem > li > a {
              display: -ms-inline-flexbox;
              display: -webkit-inline-flex;
              display: inline-flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              color: #fff;
              font-family: "Zirkel-Bold";
              font-weight: 700;
              font-size: 20px;
              font-size: 1.25rem;
              position: relative;
              padding-bottom: 20px;
              -moz-transition: all ease-in-out 200ms;
              -o-transition: all ease-in-out 200ms;
              -webkit-transition: all ease-in-out 200ms;
              transition: all ease-in-out 200ms; }
              @media (min-width: 992px) {
                header .header-right-menu #menuElem > li > a {
                  height: 56px;
                  padding-bottom: 0;
                  font-size: 18px;
                  font-size: 1.125rem; } }
              @media (min-width: 992px) {
                header .header-right-menu #menuElem > li > a:after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 4px;
                  background-color: #f73c37;
                  -moz-transition: all ease-in-out 200ms;
                  -o-transition: all ease-in-out 200ms;
                  -webkit-transition: all ease-in-out 200ms;
                  transition: all ease-in-out 200ms;
                  transition-timing-function: ease-out;
                  opacity: 0;
                  transform: translateY(100%); } }
              @media (min-width: 320px) and (max-width: 991px) {
                header .header-right-menu #menuElem > li > a:hover, header .header-right-menu #menuElem > li > a:focus {
                  color: #000; } }
              header .header-right-menu #menuElem > li > a:hover:after, header .header-right-menu #menuElem > li > a:focus:after {
                opacity: 1;
                transform: translateY(0%); }
            header .header-right-menu #menuElem > li ul {
              display: none;
              list-style-type: none;
              padding-left: 25px; }
              @media (min-width: 992px) {
                header .header-right-menu #menuElem > li ul {
                  border-top: 2px solid #999999;
                  background-color: rgba(153, 153, 153, 0.93);
                  padding: 10px 25px;
                  padding-right: 0;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  opacity: 0;
                  transform: translate(-25px, 100%);
                  columns: 2;
                  column-gap: 25px;
                  display: none !important; } }
              header .header-right-menu #menuElem > li ul li {
                padding: 0;
                margin: 0;
                min-width: 125px; }
                @media (min-width: 992px) {
                  header .header-right-menu #menuElem > li ul li {
                    min-width: 125px; } }
                header .header-right-menu #menuElem > li ul li a {
                  color: #fff;
                  font-family: "Zirkel-Black";
                  font-weight: 800;
                  font-size: 16px;
                  font-size: 1rem;
                  position: relative;
                  display: inline-block;
                  padding-bottom: 20px;
                  -moz-transition: all ease-in-out 200ms;
                  -o-transition: all ease-in-out 200ms;
                  -webkit-transition: all ease-in-out 200ms;
                  transition: all ease-in-out 200ms; }
                  @media (min-width: 992px) {
                    header .header-right-menu #menuElem > li ul li a {
                      padding-bottom: 3px;
                      margin-bottom: 7px; } }
                  @media (min-width: 992px) {
                    header .header-right-menu #menuElem > li ul li a:after {
                      content: "";
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      width: 100%;
                      height: 2px;
                      background-color: #f73c37;
                      -moz-transition: all ease-in-out 200ms;
                      -o-transition: all ease-in-out 200ms;
                      -webkit-transition: all ease-in-out 200ms;
                      transition: all ease-in-out 200ms;
                      transition-timing-function: ease-out;
                      opacity: 0; } }
                  @media (min-width: 320px) and (max-width: 991px) {
                    header .header-right-menu #menuElem > li ul li a:hover, header .header-right-menu #menuElem > li ul li a:focus {
                      color: #000; } }
                  header .header-right-menu #menuElem > li ul li a:hover:after, header .header-right-menu #menuElem > li ul li a:focus:after {
                    opacity: 1; }
            @media (min-width: 992px) {
              header .header-right-menu #menuElem > li:hover ul, header .header-right-menu #menuElem > li:focus ul {
                display: inline-block !important;
                opacity: 1; } }
    header .header-right-mobile-menu {
      height: 56px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 992px) {
        header .header-right-mobile-menu {
          display: none; } }
      header .header-right-mobile-menu-inner {
        position: relative;
        cursor: pointer;
        width: 26px;
        height: 30px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; }
        header .header-right-mobile-menu-inner span {
          width: 26px;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          background: #fff;
          height: 2px;
          left: 50%;
          margin-left: -13px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-right-mobile-menu-inner span:before, header .header-right-mobile-menu-inner span:after {
            content: "";
            width: 26px;
            height: 2px;
            position: absolute;
            display: block;
            background: #fff;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-right-mobile-menu-inner span:before {
            top: -8px; }
          header .header-right-mobile-menu-inner span:after {
            bottom: -8px; }
        @media (min-width: 992px) {
          header .header-right-mobile-menu-inner {
            display: none !important; } }
        header .header-right-mobile-menu-inner.open span {
          background: transparent; }
          header .header-right-mobile-menu-inner.open span:before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0; }
          header .header-right-mobile-menu-inner.open span:after {
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 0; }

footer {
  max-width: 1442px;
  margin: auto;
  background: #000;
  color: #fefefe;
  padding: 20px 0;
  margin-top: 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 18px;
  line-height: 1.125rem; }
  @media (min-width: 992px) {
    footer {
      padding: 35px 0; } }
  footer a {
    color: #fefefe; }
    footer a:hover, footer a:focus {
      text-decoration: underline;
      color: #fefefe; }
  footer .footer-bottom {
    padding-top: 50px; }
    @media (min-width: 992px) {
      footer .footer-bottom {
        border-top: 2px solid #303030;
        padding: 0;
        margin-top: 50px;
        height: 95px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between; } }
    footer .footer-bottom-left {
      display: none; }
      @media (min-width: 992px) {
        footer .footer-bottom-left {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: flex-end;
          -moz-box-align: flex-end;
          -ms-flex-align: flex-end;
          -webkit-align-items: flex-end;
          align-items: flex-end; } }
    @media (min-width: 992px) {
      footer .footer-bottom-right {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: flex-end;
        -moz-box-align: flex-end;
        -ms-flex-align: flex-end;
        -webkit-align-items: flex-end;
        align-items: flex-end; } }
  footer .nwl-title {
    margin-top: 40px; }
    @media (min-width: 992px) {
      footer .nwl-title {
        margin-top: 0; } }
  footer .nwl-box label {
    display: none; }
  footer .nwl-box .form-horizontal {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: flex-start;
    -moz-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media (min-width: 768px) {
      footer .nwl-box .form-horizontal {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; } }
    footer .nwl-box .form-horizontal .form-group {
      width: 100%; }
      @media (min-width: 768px) {
        footer .nwl-box .form-horizontal .form-group {
          width: auto; } }
  footer .nwl-box input[type="text"] {
    width: 100%;
    margin-bottom: 0;
    padding: 0 5px;
    height: 35px;
    line-height: 24px;
    font-size: 14px;
    color: #fefefe;
    background: transparent;
    border: 2px solid #8e8e8e;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    border-radius: 1px;
    -webkit-transition-property: border-color;
    -moz-transition-property: border-color;
    -o-transition-property: border-color;
    transition-property: border-color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    footer .nwl-box input[type="text"]:focus {
      border-color: #fff; }
    footer .nwl-box input[type="text"]:-moz-placeholder {
      color: #fff; }
    footer .nwl-box input[type="text"]::-moz-placeholder {
      color: #fff; }
    footer .nwl-box input[type="text"]::-webkit-input-placeholder {
      color: #fff; }
    footer .nwl-box input[type="text"]:-ms-input-placeholder {
      color: #fff; }
    @media (min-width: 768px) {
      footer .nwl-box input[type="text"] {
        width: 200px; } }
  footer .nwl-box input[type="submit"] {
    cursor: pointer;
    display: block;
    float: right;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    height: 35px;
    outline: none;
    text-align: center;
    text-decoration: none;
    padding: 0 !important;
    background: transparent;
    border: 2px solid #8e8e8e;
    color: #fff;
    border-radius: 1px;
    font-weight: bold;
    font-family: "Zirkel-Bold";
    -webkit-transition-property: background, border-color, color;
    -moz-transition-property: background, border-color, color;
    -o-transition-property: background, border-color, color;
    transition-property: background, border-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    footer .nwl-box input[type="submit"]:hover, footer .nwl-box input[type="submit"].active {
      border-color: #7a7a7a;
      color: #fff;
      background: #7a7a7a; }
    @media (min-width: 768px) {
      footer .nwl-box input[type="submit"] {
        width: 85px; } }
  footer .nwl-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    footer .nwl-bottom .copyright {
      padding-bottom: 10px;
      font-size: 10px;
      font-size: 0.625rem;
      line-height: 14px;
      line-height: 0.875rem; }
      @media (min-width: 768px) {
        footer .nwl-bottom .copyright {
          padding-right: 10px; } }
      @media (min-width: 992px) {
        footer .nwl-bottom .copyright {
          padding-right: 85px;
          padding-bottom: 0; } }
    @media (min-width: 768px) {
      footer .nwl-bottom {
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row; } }
  footer ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    footer ul li {
      display: inline-block;
      vertical-align: top;
      margin: 0 5px 0 -5px;
      font-size: 10px;
      font-size: 0.625rem;
      line-height: 14px;
      line-height: 0.875rem; }
      footer ul li a {
        display: block;
        padding: 5px;
        color: #fff;
        text-decoration: none; }
        footer ul li a:hover {
          color: #fff;
          text-decoration: underline; }

.footer-contact {
  min-height: 120px;
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  max-width: 273px; }
  .footer-contact-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    .footer-contact-inner-image {
      position: relative;
      top: 12px; }
      @media (min-width: 768px) {
        .footer-contact-inner-image {
          top: 16px; } }
      .footer-contact-inner-image img {
        max-width: 100%;
        height: 100px; }
        @media (min-width: 768px) {
          .footer-contact-inner-image img {
            height: 130px; } }
    .footer-contact-inner-content {
      background-color: #f73c37;
      color: #fff;
      font-size: 16px;
      font-size: 1rem;
      font-family: "Zirkel-Black";
      font-weight: 800;
      padding: 30px 30px 30px; }

.footer-logo-small-device {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 2px solid #303030;
  padding-bottom: 20px; }
  @media (min-width: 768px) {
    .footer-logo-small-device {
      display: none; } }

.footer-social {
  font-size: 0; }
  .footer-social .ico-social {
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    margin: 5px;
    height: 27px;
    width: 27px;
    text-decoration: none !important;
    position: relative; }
    .footer-social .ico-social:before {
      color: #fff;
      -webkit-transition-property: color;
      -moz-transition-property: color;
      -o-transition-property: color;
      transition-property: color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      font-size: 27px;
      font-size: 1.6875rem;
      position: absolute;
      top: 0;
      left: 0; }
    .footer-social .ico-social:focus:before, .footer-social .ico-social:hover:before {
      color: #f73c37; }
    .footer-social .ico-social:first-child {
      margin: 5px 5px 5px 0; }
      @media (min-width: 768px) {
        .footer-social .ico-social:first-child {
          margin: 5px; } }
    .footer-social .ico-social.s-google:before {
      content: ""; }
    .footer-social .ico-social.s-tumblr:before {
      content: ""; }
    .footer-social .ico-social.s-facebook:before {
      content: ""; }
    .footer-social .ico-social.s-mail:before {
      content: ""; }
    .footer-social .ico-social.s-twitter:before {
      content: ""; }
    .footer-social .ico-social.s-linkedin:before {
      content: ""; }
    .footer-social .ico-social.s-vine:before {
      content: ""; }
    .footer-social .ico-social.s-youtube:before {
      content: ""; }

.bottom-logo img {
  width: 70px;
  height: auto; }
  @media (min-width: 992px) {
    .bottom-logo img {
      width: 120px; } }

.bottom-logo-text {
  margin-top: 5px;
  font-size: 8px;
  font-size: 0.5rem;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .bottom-logo-text {
      font-size: 10px;
      font-size: 0.625rem; } }

.block-medium {
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .block-medium {
      margin-top: 35px;
      margin-bottom: 35px; } }
  @media (min-width: 992px) {
    .block-medium {
      margin-top: 40px;
      margin-bottom: 40px; } }

.block-large {
  margin-top: 20px;
  margin-bottom: 20px; }
  @media (min-width: 545px) {
    .block-large {
      margin-top: 30px;
      margin-bottom: 30px; } }
  @media (min-width: 768px) {
    .block-large {
      margin-top: 40px;
      margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .block-large {
      margin-top: 60px;
      margin-bottom: 60px; } }
  @media (min-width: 1400px) {
    .block-large {
      margin-top: 70px;
      margin-bottom: 70px; } }

.block-map {
  margin: 40px 0; }
  @media (min-width: 992px) {
    .block-map {
      margin-top: 35px;
      margin-bottom: 60px; } }

.items-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.page-info {
  background-color: #000;
  color: #fff;
  padding-bottom: 45px; }
  .page-info-bredcrumbs {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 700; }
    .page-info-bredcrumbs a {
      color: #fff;
      font-size: 14px;
      font-size: 0.875rem; }
  .page-info-text {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column nowrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    @media (min-width: 768px) {
      .page-info-text {
        -moz-flex-flow: row nowrap;
        -ms-flex-direction: row;
        -ms-flex-wrap: nowrap;
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap; } }
    .page-info-text-name {
      margin-top: 20px; }
      @media (min-width: 768px) {
        .page-info-text-name {
          -webkit-flex: 0 1 250px;
          -moz-flex: 0 1 250px;
          -ms-flex: 0 1 250px;
          -webkit-flex: 0 1 250px;
          flex: 0 1 250px;
          max-width: 250px;
          margin-top: 40px; } }
      @media (min-width: 1200px) {
        .page-info-text-name {
          -webkit-flex: 0 1 350px;
          -moz-flex: 0 1 350px;
          -ms-flex: 0 1 350px;
          -webkit-flex: 0 1 350px;
          flex: 0 1 350px;
          max-width: 350px;
          margin-top: 40px; } }
    .page-info-text-description {
      order: -1;
      font-size: 18px;
      font-size: 1.125rem;
      margin-top: 30px;
      line-height: 1.4; }
      @media (min-width: 768px) {
        .page-info-text-description {
          order: 2;
          font-size: 20px;
          font-size: 1.25rem;
          -webkit-flex: 0 1 auto;
          -moz-flex: 0 1 auto;
          -ms-flex: 0 1 auto;
          -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
          max-width: calc(100% - 250px);
          margin-top: 40px; } }
      @media (min-width: 1200px) {
        .page-info-text-description {
          max-width: calc(100% - 350px); } }

@media (min-width: 768px) {
  .header-no-menu .page-info-text-name {
    -webkit-flex: 0 1 50%;
    -moz-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    -webkit-flex: 0 1 50%;
    flex: 0 1 50%;
    max-width: 50%;
    margin-top: 0; } }

.header-no-menu .page-info-text-description {
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 40px;
  line-height: 1.5;
  order: 2; }
  @media (min-width: 768px) {
    .header-no-menu .page-info-text-description {
      font-size: 18px;
      font-size: 1.125rem;
      -webkit-flex: 0 1 50%;
      -moz-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      -webkit-flex: 0 1 50%;
      flex: 0 1 50%;
      max-width: 50%;
      margin-top: 0; } }

@media (min-width: 768px) {
  .our-brands-padding .page-info-text-description {
    padding-bottom: 150px; } }

@media (min-width: 1200px) {
  .our-brands-padding .page-info-text-description {
    padding-bottom: 170px; } }

.base-container {
  width: 100%; }
  @media (min-width: 768px) {
    .base-container {
      padding-left: 250px; } }
  @media (min-width: 1200px) {
    .base-container {
      padding-left: 350px; } }

.margin-y-20 {
  margin-top: 10px;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .margin-y-20 {
      margin-top: 20px;
      margin-bottom: 20px; } }

.margin-bottom- {
  margin-top: 10px;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .margin-bottom- {
      margin-top: 20px;
      margin-bottom: 20px; } }

.margin-right-small {
  margin-right: 25px; }

.base-padding {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 1200px) {
    .base-padding {
      padding-left: 20px;
      padding-right: 20px; } }

.mt-20 {
  margin-top: 20px; }

.base-margin-top {
  margin-top: 20px; }
  @media (min-width: 1400px) {
    .base-margin-top {
      margin-top: 30px; } }

.full-height {
  height: 100%; }

.block-search {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    .block-search {
      padding-top: 50px;
      padding-bottom: 50px; } }

.map-area-image {
  position: relative; }
  .map-area-image .language-area-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 33; }
  .map-area-image img {
    max-width: 100%; }

.modal-language-country {
  color: #fff !important;
  display: inline-block; }
  .modal-language-country:focus, .modal-language-country.hover {
    color: #f73c37 !important; }

.menu-layer:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  opacity: 0;
  animation: menu-layer 150ms linear forwards;
  animation-delay: 100ms;
  z-index: 99; }
  @media (min-width: 992px) {
    .menu-layer:before {
      display: none !important; } }

@keyframes menu-layer {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.page-content-padding {
  padding-top: 20px; }
  @media (min-width: 768px) {
    .page-content-padding {
      padding-top: 40px; } }

.space-10 .row,
.space-10 .items-container {
  margin-left: -10px;
  margin-right: -10px; }
  .space-10 .row [class*="col-"],
  .space-10 .items-container [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; }

.padding-box {
  padding: 30px 23px; }
  @media (min-width: 768px) {
    .padding-box {
      padding: 30px 35px; } }

.red-box {
  background-color: #f73c37;
  color: #fff;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .red-box {
      margin-top: 40px; } }
  @media (min-width: 992px) {
    .red-box {
      margin-top: 75px; } }
  .red-box h1,
  .red-box .h1,
  .red-box h2,
  .red-box .h2,
  .red-box h3,
  .red-box .h3,
  .red-box h4,
  .red-box .h4,
  .red-box h5,
  .red-box .h5 {
    border-bottom: 1px solid #fff !important; }

.grey-box {
  background-color: #e1e1e1;
  color: #000;
  margin-top: 20px; }
  @media (min-width: 768px) {
    .grey-box {
      margin-top: 30px; } }
  @media (min-width: 992px) {
    .grey-box {
      margin-top: 40px; } }

.form-box {
  max-width: 405px; }

.margin-top-0 {
  margin-top: 0 !important; }

.map-area-image {
  position: relative; }
  .map-area-image .language-area-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 33; }

.modal-language-country {
  color: #fff !important;
  display: inline-block; }
  .modal-language-country:focus, .modal-language-country:hover, .modal-language-country.hover {
    color: #f73c37 !important; }

.map-area-image {
  display: none; }
  @media (min-width: 545px) {
    .map-area-image {
      display: block; } }

.language-item {
  margin: 15px 0; }
  @media (min-width: 768px) {
    .language-item {
      margin: 30px 0; } }
  @media (min-width: 992px) {
    .language-item {
      max-width: 20%;
      flex: 0 0 20%; } }
  @media (min-width: 1400px) {
    .language-item {
      flex: 0 1 16.666%;
      max-width: 16.666%; } }

.bottom-margin-large {
  margin-bottom: 180px; }
  @media (min-width: 768px) {
    .bottom-margin-large {
      margin-bottom: 220px; } }

.bottom-margin-large-md {
  margin-bottom: 180px; }
  @media (min-width: 768px) {
    .bottom-margin-large-md {
      margin-bottom: 0; } }

.bg-home {
  width: 100%; }
  @media (min-width: 992px) {
    .bg-home {
      background-image: url("/Direct/media/content/background-home.png");
      background-position: center top;
      background-size: auto 100%;
      background-repeat: no-repeat; } }
  .bg-home.can-order {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column nowrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap; }
  .bg-home .order-to-first {
    order: -1; }

@media (min-width: 992px) {
  .browser-edge #menuElem ul,
  .IE11 #menuElem ul {
    left: 0;
    transform: translate(10%, 100%) !important; } }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "Zirkel-Black";
  src: url("/Direct/media/system/fonts/Zirkel-Black.otf"), url("/Direct/media/system/fonts/Zirkel-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "Zirkel-Bold";
  src: url("/Direct/media/system/fonts/Zirkel-Bold.otf"), url("/Direct/media/system/fonts/Zirkel-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Zirkel-Regular";
  src: url("/Direct/media/system/fonts/Zirkel-Regular.otf"), url("/Direct/media/system/fonts/Zirkel-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Zirkel-Black";
  src: url("/Direct/media/system/fonts/zirkel-black.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "Zirkel-Bold";
  src: url("/Direct/media/system/fonts/zirkel-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Zirkel-Regular";
  src: url("/Direct/media/system/fonts/zirkel-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

img {
  max-width: 100%; }

.text-editor {
  font-size: 18px;
  font-size: 1.125rem; }
  .text-editor img {
    width: 100% !important;
    height: auto !important;
    margin: 20px 0; }
    @media (min-width: 768px) {
      .text-editor img {
        margin: 30px 0; } }

body,
input,
select,
textarea {
  font-family: 'Zirkel-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-size: 16px;
  color: #fff; }

body {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #fff;
  max-width: 1442px;
  position: relative;
  margin: 0 auto; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  font-weight: 800; }

h1, .h1 {
  font-family: 'Zirkel-Black';
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 0.97; }
  @media (min-width: 545px) {
    h1, .h1 {
      font-size: 58px;
      font-size: 3.625rem; } }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 60px;
      font-size: 3.75rem; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 66px;
      font-size: 4.125rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 72px;
      font-size: 4.5rem; } }

h2, .h2 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.1; }

h3, .h3 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.18; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 26px;
      font-size: 1.625rem; } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 28px;
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 22px;
      font-size: 1.375rem; } }

h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.33; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 18px;
      font-size: 1.125rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h5, .h5 {
  font-size: 16px;
  font-size: 1rem; }

h6, .h6 {
  font-size: 14px;
  font-size: 0.875rem; }

.password-strength-weak {
  color: #b22222; }

.password-strength-acceptable {
  color: #f4a460; }

.password-strength-average {
  color: #4169e1; }

.password-strength-strong {
  color: #3cb371; }

.password-strength-excellent {
  color: #228b22; }

strong,
.bold,
b {
  font-weight: 600; }

.semibold {
  font-weight: 600; }

a {
  color: #000;
  text-decoration: none !important; }
  a:hover, a:focus {
    color: #f73c37; }

.title-line {
  padding-bottom: 5px;
  border-bottom: 2px solid #000;
  margin-bottom: 20px; }

.title-line-slim {
  font-weight: bold;
  padding-bottom: 3px;
  border-bottom: 1px solid #000;
  margin-bottom: 5px; }

.title-line-respons {
  padding-bottom: 0px;
  border-bottom: 1px solid #000;
  margin-bottom: 3px; }
  @media (min-width: 480px) {
    .title-line-respons {
      border-bottom: 2px solid #000;
      margin-bottom: 5px; } }

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 1px solid #000;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  color: #000;
  text-align: center; }
  .square-button:focus, .square-button:hover,
  .square-button-bw:focus,
  .square-button-bw:hover {
    color: #fff !important;
    background: #f73c37;
    border: 1px solid #f73c37;
    text-decoration: none !important; }

.square-button-bw:focus, .square-button-bw:hover {
  color: #fff !important;
  background: #000; }

.big-button-bw,
.big-button-bw-small,
.arrow-down-link,
.btn-show-more {
  width: auto;
  padding: 0 15px;
  min-height: 40px;
  line-height: 38px;
  vertical-align: middle;
  display: inline-block;
  font-family: 'Zirkel-Bold';
  font-size: 18px;
  border: 2px solid #000;
  color: #000;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  text-align: center;
  -webkit-transition-property: background, color;
  -moz-transition-property: background, color;
  -o-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease; }
  .big-button-bw:hover, .big-button-bw:focus,
  .big-button-bw-small:hover,
  .big-button-bw-small:focus,
  .arrow-down-link:hover,
  .arrow-down-link:focus,
  .btn-show-more:hover,
  .btn-show-more:focus {
    color: #fff !important;
    background: #f73c37;
    text-decoration: none;
    border: 2px solid #f73c37; }

.big-button-bw-small,
.arrow-down-link {
  font-size: 15px;
  padding: 0 10px; }
  @media (min-width: 768px) {
    .big-button-bw-small,
    .arrow-down-link {
      font-size: 18px;
      font-size: 1.125rem;
      padding: 0 20px; } }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 10px 15px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  white-space: normal !important;
  max-width: 100%; }
  @media (min-width: 768px) {
    .btn {
      width: 250px; } }
  @media (min-width: 1200px) {
    .btn {
      width: 335px; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary,
input[type=submit] {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  padding: 10px 25px;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  cursor: pointer;
  color: #000; }
  .btn-primary:focus, .btn-primary:hover,
  input[type=submit]:focus,
  input[type=submit]:hover {
    text-decoration: none;
    background: #f73c37;
    color: #fff;
    border: 2px solid #f73c37; }
  @media (min-width: 992px) {
    .btn-primary.btn-big,
    input[type=submit].btn-big {
      padding: 18px 55px; } }

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #fff !important;
  color: #fff !important;
  text-shadow: 0;
  opacity: 1;
  font-size: 15px;
  font-size: 0.9375rem;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .btn-close:hover, .btn-close:focus {
    color: #fff !important;
    background-color: #fff; }

.ErrorLabel,
.EditingFormErrorLabel {
  position: relative;
  display: block;
  width: 100%;
  background-color: #231f20; }

.footer-text {
  color: #fefefe;
  padding-right: 17.5%;
  margin-top: 40px; }
  @media (min-width: 992px) {
    .footer-text {
      margin-top: 0; } }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.pt-25 {
  padding-top: 25px; }

.pb-25 {
  padding-bottom: 25px; }

.mt-25 {
  margin-top: 25px; }

.mb-25 {
  margin-bottom: 25px; }

.pt-30 {
  padding-top: 30px; }

.pb-30 {
  padding-bottom: 30px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.pt-35 {
  padding-top: 35px; }

.pb-35 {
  padding-bottom: 35px; }

.mt-35 {
  margin-top: 35px; }

.mb-35 {
  margin-bottom: 35px; }

.pt-40 {
  padding-top: 40px; }

.pb-40 {
  padding-bottom: 40px; }

.mt-40 {
  margin-top: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.pt-45 {
  padding-top: 45px; }

.pb-45 {
  padding-bottom: 45px; }

.mt-45 {
  margin-top: 45px; }

.mb-45 {
  margin-bottom: 45px; }

.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.mt-50 {
  margin-top: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.pt-55 {
  padding-top: 55px; }

.pb-55 {
  padding-bottom: 55px; }

.mt-55 {
  margin-top: 55px; }

.mb-55 {
  margin-bottom: 55px; }

.pt-60 {
  padding-top: 60px; }

.pb-60 {
  padding-bottom: 60px; }

.mt-60 {
  margin-top: 60px; }

.mb-60 {
  margin-bottom: 60px; }

.pt-65 {
  padding-top: 65px; }

.pb-65 {
  padding-bottom: 65px; }

.mt-65 {
  margin-top: 65px; }

.mb-65 {
  margin-bottom: 65px; }

.pt-70 {
  padding-top: 70px; }

.pb-70 {
  padding-bottom: 70px; }

.mt-70 {
  margin-top: 70px; }

.mb-70 {
  margin-bottom: 70px; }

.pt-75 {
  padding-top: 75px; }

.pb-75 {
  padding-bottom: 75px; }

.mt-75 {
  margin-top: 75px; }

.mb-75 {
  margin-bottom: 75px; }

.pt-80 {
  padding-top: 80px; }

.pb-80 {
  padding-bottom: 80px; }

.mt-80 {
  margin-top: 80px; }

.mb-80 {
  margin-bottom: 80px; }

.pt-85 {
  padding-top: 85px; }

.pb-85 {
  padding-bottom: 85px; }

.mt-85 {
  margin-top: 85px; }

.mb-85 {
  margin-bottom: 85px; }

.pt-90 {
  padding-top: 90px; }

.pb-90 {
  padding-bottom: 90px; }

.mt-90 {
  margin-top: 90px; }

.mb-90 {
  margin-bottom: 90px; }

.pt-95 {
  padding-top: 95px; }

.pb-95 {
  padding-bottom: 95px; }

.mt-95 {
  margin-top: 95px; }

.mb-95 {
  margin-bottom: 95px; }

.pt-100 {
  padding-top: 100px; }

.pb-100 {
  padding-bottom: 100px; }

.mt-100 {
  margin-top: 100px; }

.mb-100 {
  margin-bottom: 100px; }

.afi-red {
  color: #f73c37; }

.title-underline h1,
.title-underline .h1,
.title-underline h2,
.title-underline .h2,
.title-underline h3,
.title-underline .h3,
.title-underline h4,
.title-underline .h4,
.title-underline h5,
.title-underline .h5 {
  display: inline-block;
  border-bottom: 1px solid #000;
  font-weight: 900;
  font-family: 'Zirkel-Black';
  margin-bottom: 25px; }

.top-banner {
  min-height: 745px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  color: #fff; }
  @media (min-width: 768px) {
    .top-banner {
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap; } }
  @media (min-width: 992px) {
    .top-banner {
      margin-top: 56px; } }
  .top-banner-image {
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding-top: 56px; }
    @media (min-width: 768px) {
      .top-banner-image {
        -webkit-box-pack: flex-end;
        -moz-box-pack: flex-end;
        -ms-flex-pack: flex-end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-flex: 0 1 59%;
        -moz-flex: 0 1 59%;
        -ms-flex: 0 1 59%;
        -webkit-flex: 0 1 59%;
        flex: 0 1 59%;
        max-width: 59%;
        min-height: 0; } }
    @media (min-width: 992px) {
      .top-banner-image {
        padding: 0; } }
    .top-banner-image:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5); }
    .top-banner-image-inner {
      width: 100%;
      position: relative;
      padding: 40px 30px; }
      @media (min-width: 545px) {
        .top-banner-image-inner {
          padding: 60px 30px; } }
      @media (min-width: 768px) {
        .top-banner-image-inner {
          padding: 100px 30px; } }
      @media (min-width: 768px) {
        .top-banner-image-inner {
          max-width: 718.5px;
          padding: 60px 30px; } }
      @media (min-width: 1200px) {
        .top-banner-image-inner {
          padding: 80px 15px;
          padding-left: 48px; } }
      @media (min-width: 1400px) {
        .top-banner-image-inner {
          padding: 100px 0px;
          padding-left: 33px; } }
      .top-banner-image-inner-link {
        position: absolute;
        bottom: 32px;
        left: 30px; }
        @media (min-width: 1200px) {
          .top-banner-image-inner-link {
            left: 48px; } }
        @media (min-width: 1400px) {
          .top-banner-image-inner-link {
            left: 33px; } }
        .top-banner-image-inner-link a {
          color: #fff;
          font-size: 16px;
          font-size: 1rem;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          @media (min-width: 992px) {
            .top-banner-image-inner-link a {
              font-size: 18px;
              font-size: 1.125rem; } }
          .top-banner-image-inner-link a:hover, .top-banner-image-inner-link a:focus {
            color: #f73c37; }
  .top-banner-content {
    background-color: #f73c37;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 768px) {
      .top-banner-content {
        -webkit-flex: 0 1 41%;
        -moz-flex: 0 1 41%;
        -ms-flex: 0 1 41%;
        -webkit-flex: 0 1 41%;
        flex: 0 1 41%;
        max-width: 41%;
        padding-top: 56px; } }
    @media (min-width: 992px) {
      .top-banner-content {
        padding: 0; } }
    .top-banner-content-inner {
      width: 100%;
      padding: 40px 30px; }
      @media (min-width: 768px) {
        .top-banner-content-inner {
          max-width: 471.5px;
          padding: 50px 40px;
          padding-right: 15%; } }
      @media (min-width: 1400px) {
        .top-banner-content-inner {
          padding: 70px 60px;
          padding-right: 15%; } }
      .top-banner-content-inner-title {
        font-size: 18px;
        font-size: 1.125rem;
        font-weight: 800;
        margin-bottom: 10px;
        letter-spacing: 1px; }
        @media (min-width: 992px) {
          .top-banner-content-inner-title {
            margin-bottom: 20px; } }
      .top-banner-content-inner-name {
        margin-bottom: 0px;
        line-height: 1;
        letter-spacing: 1px; }
        @media (min-width: 320px) and (max-width: 767px) {
          .top-banner-content-inner-name {
            font-size: 28px;
            font-size: 1.75rem; } }
        .top-banner-content-inner-name a {
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          display: inline-block; }
          .top-banner-content-inner-name a:hover, .top-banner-content-inner-name a:focus {
            color: #000; }

.category-container {
  width: 100%;
  border-top: 2px solid #d8d8d8;
  border-left: 2px solid #d8d8d8;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }
  @media (min-width: 768px) {
    .category-container {
      display: block; } }
  .category-container:after {
    clear: both;
    content: "";
    display: block; }

.category-item {
  display: block;
  border-right: 2px solid #d8d8d8;
  border-bottom: 2px solid #d8d8d8;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  background-color: #fff;
  overflow: hidden; }
  @media (min-width: 768px) {
    .category-item {
      float: left; } }
  .category-item-image {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-flex: 0 1 100%;
    -moz-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    -webkit-flex: 0 1 100%;
    flex: 0 1 100%;
    overflow: hidden;
    -moz-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    -webkit-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }
  .category-item-name {
    -webkit-flex: 0 1 40px;
    -moz-flex: 0 1 40px;
    -ms-flex: 0 1 40px;
    -webkit-flex: 0 1 40px;
    flex: 0 1 40px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 800;
    position: relative;
    padding-bottom: 5px;
    background-color: #e1e1e1;
    padding: 4px 8px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .category-item-name {
        font-size: 18px;
        font-size: 1.125rem;
        -webkit-flex: 0 1 50px;
        -moz-flex: 0 1 50px;
        -ms-flex: 0 1 50px;
        -webkit-flex: 0 1 50px;
        flex: 0 1 50px;
        padding: 6px 12px;
        padding-bottom: 10px; } }
    .category-item-name:before {
      display: block;
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #000; }
    .category-item-name:after {
      content: "";
      display: block;
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      margin-top: 2px;
      background-color: #000;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .category-item:hover .category-item-image, .category-item:focus .category-item-image {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1); }
  .category-item:hover .category-item-name:after, .category-item:focus .category-item-name:after {
    width: 100%;
    background-color: #f73c37; }

.category-big-link {
  background-color: #f73c37;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .category-big-link-content {
    font-size: 18px;
    font-size: 1.125rem;
    color: #fff;
    font-weight: 800;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column nowrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    @media (min-width: 768px) {
      .category-big-link-content {
        -webkit-flex: 0 1 60%;
        -moz-flex: 0 1 60%;
        -ms-flex: 0 1 60%;
        -webkit-flex: 0 1 60%;
        flex: 0 1 60%;
        max-width: 60%;
        padding: 10px 15px; } }
    @media (min-width: 992px) {
      .category-big-link-content {
        font-size: 22px;
        font-size: 1.375rem;
        padding: 20px 25px; } }
    .category-big-link-content-link {
      font-size: 16px;
      font-size: 1rem;
      font-weight: 300 !important;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 992px) {
        .category-big-link-content-link {
          font-size: 18px;
          font-size: 1.125rem; } }
      .category-big-link-content-link:after {
        content: "\e908";
        background: transparent;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #fff;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        width: 26px;
        height: 26px;
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 26px;
        margin-left: 10px; }
  .category-big-link-img {
    -webkit-flex: 0 1 40%;
    -moz-flex: 0 1 40%;
    -ms-flex: 0 1 40%;
    -webkit-flex: 0 1 40%;
    flex: 0 1 40%;
    max-width: 40%;
    display: none; }
    @media (min-width: 768px) {
      .category-big-link-img {
        display: block; } }
    .category-big-link-img-inner {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: flex-end;
      -moz-box-align: flex-end;
      -ms-flex-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      height: 100%; }
      .category-big-link-img-inner img {
        max-height: 100%;
        max-width: 100%;
        width: 100%; }
  .category-big-link:hover, .category-big-link:focus {
    background-color: #f1100a; }

.display-none-to-md {
  display: none; }
  @media (min-width: 768px) {
    .display-none-to-md {
      display: block; } }

.mnsr-item-1 {
  width: 50%;
  height: 193px; }
  @media (min-width: 768px) {
    .mnsr-item-1 {
      width: 25%; } }
  @media (min-width: 992px) {
    .mnsr-item-1 {
      width: 20%; } }
  @media (min-width: 1400px) {
    .mnsr-item-1 {
      width: 16.66666666%; } }

.mnsr-item-width-2 {
  width: 50%; }
  @media (min-width: 992px) {
    .mnsr-item-width-2 {
      width: 40%; } }
  @media (min-width: 1400px) {
    .mnsr-item-width-2 {
      width: 33.3333333%; } }

@media (min-width: 768px) {
  .mnsr-item-height-2 {
    height: 386px; } }

.margin-top-container {
  margin-top: 15px; }
  @media (min-width: 768px) {
    .margin-top-container {
      margin-top: 20px; } }
  @media (min-width: 1200px) {
    .margin-top-container {
      margin-top: 25px; } }

.service-card {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: space-between;
  -moz-box-align: space-between;
  -ms-flex-align: space-between;
  -webkit-align-items: space-between;
  align-items: space-between;
  border-bottom: 2px solid #d8d8d8;
  padding: 25px 20px 20px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  position: relative; }
  @media (min-width: 768px) {
    .service-card {
      -webkit-flex: 0 1 33.3333%;
      -moz-flex: 0 1 33.3333%;
      -ms-flex: 0 1 33.3333%;
      -webkit-flex: 0 1 33.3333%;
      flex: 0 1 33.3333%;
      max-width: 33.3333%;
      border: 2px solid #d8d8d8; } }
  @media (min-width: 992px) {
    .service-card {
      padding: 35px 25px 25px; } }
  .service-card-title {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.1; }
  .service-card-content {
    margin-top: 80px;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.3; }
  .service-card:not(:first-of-type) {
    border-left: 0; }
  .service-card.theme-black {
    background-color: #000;
    color: #fff; }
    .service-card.theme-black:hover, .service-card.theme-black:focus {
      color: #fff; }
  .service-card.theme-gray {
    background-color: #e1e1e1; }
  .service-card.theme-white {
    background-color: #fff; }
  @media (min-width: 768px) {
    .service-card-container {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; } }

.news-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }

.news-item-hp {
  -webkit-flex: 0 1 100%;
  -moz-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  -webkit-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  display: block;
  border: 1px solid #c7c7c7;
  background-color: #fff; }
  @media (min-width: 545px) {
    .news-item-hp {
      -webkit-flex: 0 1 50%;
      -moz-flex: 0 1 50%;
      -ms-flex: 0 1 50%;
      -webkit-flex: 0 1 50%;
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .news-item-hp {
      -webkit-flex: 0 1 25%;
      -moz-flex: 0 1 25%;
      -ms-flex: 0 1 25%;
      -webkit-flex: 0 1 25%;
      flex: 0 1 25%;
      max-width: 25%; } }
  @media (min-width: 320px) and (max-width: 1399) {
    .news-item-hp:not(:first-of-type) {
      border-top: 0; } }
  @media (min-width: 992px) {
    .news-item-hp:not(:first-of-type) {
      border-left: 0; } }
  @media (min-width: 545px) and (max-width: 991px) {
    .news-item-hp:nth-of-type(2n + 2) {
      border-left: 0; } }
  .news-item-hp-image {
    background-position: center !important;
    background-size: 100% auto !important;
    background-repeat: no-repeat !important;
    border-bottom: 10px solid #000; }
    .news-item-hp-image:before {
      content: "";
      display: block;
      padding-top: 65%; }
  .news-item-hp-content {
    padding: 15px 20px;
    font-size: 14px;
    font-size: 0.875rem;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      .news-item-hp-content {
        padding: 20px; } }
    .news-item-hp-content-title {
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 800;
      border-bottom: 1px solid #000;
      padding-bottom: 5px;
      margin-bottom: 15px; }
  .news-item-hp.color-mss .news-item-hp-image {
    position: relative;
    border-color: #ffd100; }
    .news-item-hp.color-mss .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 209, 0, 0.4); }
  .news-item-hp.color-jte .news-item-hp-image {
    position: relative;
    border-color: #1f21ab; }
    .news-item-hp.color-jte .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(31, 33, 171, 0.4); }
  .news-item-hp.color-litec .news-item-hp-image {
    position: relative;
    border-color: #043a63; }
    .news-item-hp.color-litec .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(4, 58, 99, 0.4); }
  .news-item-hp.color-tomcat .news-item-hp-image {
    position: relative;
    border-color: #009641; }
    .news-item-hp.color-tomcat .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 150, 65, 0.4); }
  .news-item-hp.color-xtruss .news-item-hp-image {
    position: relative;
    border-color: #f7403b; }
    .news-item-hp.color-xtruss .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(247, 64, 59, 0.4); }
  .news-item-hp.color-xstage .news-item-hp-image {
    position: relative;
    border-color: #f7403b; }
    .news-item-hp.color-xstage .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(247, 64, 59, 0.4); }
  .news-item-hp.color-mobiltechlifts .news-item-hp-image {
    position: relative;
    border-color: #2c4390; }
    .news-item-hp.color-mobiltechlifts .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(44, 67, 144, 0.4); }
  .news-item-hp.color-exe .news-item-hp-image {
    position: relative;
    border-color: #424242; }
    .news-item-hp.color-exe .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(66, 66, 66, 0.4); }
  .news-item-hp.color-stagedex .news-item-hp-image {
    position: relative;
    border-color: #ff4d00; }
    .news-item-hp.color-stagedex .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 77, 0, 0.4); }
  .news-item-hp.color-prolyft .news-item-hp-image {
    position: relative;
    border-color: #8cbf30; }
    .news-item-hp.color-prolyft .news-item-hp-image:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(140, 191, 48, 0.4); }

.location-block {
  margin-top: 60px;
  margin-bottom: 180px; }
  @media (min-width: 768px) {
    .location-block {
      margin-top: 90px;
      margin-bottom: 200px; } }
  @media (min-width: 992px) {
    .location-block {
      margin-top: 130px;
      margin-bottom: 212px; } }

@media (min-width: 768px) {
  .locations {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; } }

.locations-content {
  background-color: #f73c37;
  padding: 50px 30px;
  color: #fff;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.3; }
  @media (min-width: 768px) {
    .locations-content {
      -webkit-flex: 0 1 32.5%;
      -moz-flex: 0 1 32.5%;
      -ms-flex: 0 1 32.5%;
      -webkit-flex: 0 1 32.5%;
      flex: 0 1 32.5%;
      max-width: 32.5%; } }
  @media (min-width: 992px) {
    .locations-content {
      font-size: 28px;
      font-size: 1.75rem; } }

.locations-map {
  height: 265px; }
  @media (min-width: 768px) {
    .locations-map {
      min-height: 440px;
      height: auto;
      -webkit-flex: 0 1 67.5%;
      -moz-flex: 0 1 67.5%;
      -ms-flex: 0 1 67.5%;
      -webkit-flex: 0 1 67.5%;
      flex: 0 1 67.5%;
      max-width: 67.5%; } }

.map-points {
  display: none; }

.product-category {
  margin-top: 30px; }
  @media (min-width: 768px) {
    .product-category {
      margin-top: 35px; } }
  @media (min-width: 992px) {
    .product-category {
      margin-top: 40px; } }
  .product-category ul {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .product-category ul li {
      -webkit-flex: 0 1 100%;
      -moz-flex: 0 1 100%;
      -ms-flex: 0 1 100%;
      -webkit-flex: 0 1 100%;
      flex: 0 1 100%;
      max-width: 100%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      padding-bottom: 1px; }
      @media (min-width: 545px) {
        .product-category ul li {
          -webkit-flex: 0 1 50%;
          -moz-flex: 0 1 50%;
          -ms-flex: 0 1 50%;
          -webkit-flex: 0 1 50%;
          flex: 0 1 50%;
          max-width: 50%;
          padding-right: 1px; } }
      @media (min-width: 768px) {
        .product-category ul li {
          -webkit-flex: 0 1 33.3333%;
          -moz-flex: 0 1 33.3333%;
          -ms-flex: 0 1 33.3333%;
          -webkit-flex: 0 1 33.3333%;
          flex: 0 1 33.3333%;
          max-width: 33.3333%; } }
      @media (min-width: 992px) {
        .product-category ul li {
          -webkit-flex: 0 1 25%;
          -moz-flex: 0 1 25%;
          -ms-flex: 0 1 25%;
          -webkit-flex: 0 1 25%;
          flex: 0 1 25%;
          max-width: 25%; } }
      @media (min-width: 1200px) {
        .product-category ul li {
          -webkit-flex: 0 1 20%;
          -moz-flex: 0 1 20%;
          -ms-flex: 0 1 20%;
          -webkit-flex: 0 1 20%;
          flex: 0 1 20%;
          max-width: 20%; } }
      .product-category ul li a {
        width: 100%;
        height: 100%;
        background-color: #f1f0f1;
        display: block;
        color: #000;
        font-family: "Zirkel-Black";
        font-weight: 800;
        font-size: 18px;
        font-size: 1.125rem;
        min-height: 50px;
        padding: 10px 15px 10px 25px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        @media (min-width: 545px) {
          .product-category ul li a {
            padding: 10px 10px 10px 20px; } }
        .product-category ul li a:after {
          content: "\e908";
          display: inline-block;
          font-size: 26px;
          font-size: 1.625rem;
          color: #000 !important; }
        .product-category ul li a:hover, .product-category ul li a:focus, .product-category ul li a.active {
          background-color: #f73c37;
          color: #000; }

.line-gray {
  background-color: #dfdfdf;
  width: 100%;
  height: 2px;
  display: none;
  margin: 45px 0; }
  @media (min-width: 768px) {
    .line-gray {
      display: block; } }

.product-filter {
  margin-top: 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .product-filter {
      -moz-flex-flow: row wrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: wrap;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap; } }
  @media (min-width: 992px) {
    .product-filter {
      margin-top: 50px; } }
  .product-filter-items {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0; }
    .product-filter-items li {
      margin: 10px 0; }
      @media (min-width: 768px) {
        .product-filter-items li {
          margin: 20px 0; } }
      .product-filter-items li a {
        margin-right: 30px;
        display: inline-block;
        cursor: pointer; }
        .product-filter-items li a:before {
          display: block;
          font-size: 22px;
          font-size: 1.375rem;
          color: #000;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          @media (min-width: 768px) {
            .product-filter-items li a:before {
              font-size: 26px;
              font-size: 1.625rem; } }
        .product-filter-items li a.logo-litec:before {
          content: "\e91c"; }
        .product-filter-items li a.logo-litec.active:before {
          color: #055694; }
        .product-filter-items li a.logo-mss:before {
          content: "\e91b"; }
        .product-filter-items li a.logo-mss.active:before {
          color: #ffd100; }
        .product-filter-items li a.logo-jte:before {
          content: "\e923"; }
        .product-filter-items li a.logo-jte.active:before {
          color: #1f21ab; }
        .product-filter-items li a.logo-flexa:before {
          content: "\e93f"; }
        .product-filter-items li a.logo-flexa.active:before {
          color: #000; }
        .product-filter-items li a.logo-tomcat:before {
          content: "\e91d"; }
        .product-filter-items li a.logo-tomcat.active:before {
          color: #009641; }
        .product-filter-items li a.logo-xtruss:before {
          content: "\e927"; }
        .product-filter-items li a.logo-xtruss.active:before {
          color: #f7403b; }
        .product-filter-items li a.logo-xstage:before {
          content: "\e926";
          font-size: 25px;
          font-size: 1.5625rem; }
          @media (min-width: 768px) {
            .product-filter-items li a.logo-xstage:before {
              font-size: 29px;
              font-size: 1.8125rem; } }
        .product-filter-items li a.logo-xstage.active:before {
          color: #f7403b; }
        .product-filter-items li a.logo-mobiltechlifts:before {
          content: "\e925"; }
        .product-filter-items li a.logo-mobiltechlifts.active:before {
          color: #f7403b; }
        .product-filter-items li a.logo-exe:before {
          content: "\e924"; }
        .product-filter-items li a.logo-exe.active:before {
          color: #f7403b; }
        .product-filter-items li a.logo-prolyte:before {
          content: "\e90b"; }
        .product-filter-items li a.logo-prolyte.active:before {
          color: #44a1d6; }
        .product-filter-items li a.logo-stagedex:before {
          content: "\e93e";
          font-size: 40px;
          font-size: 2.5rem;
          transform: translateY(-4px); }
          @media (min-width: 768px) {
            .product-filter-items li a.logo-stagedex:before {
              font-size: 45px;
              font-size: 2.8125rem;
              transform: translateY(-6px); } }
        .product-filter-items li a.logo-stagedex.active:before {
          color: #ff4d00; }
        .product-filter-items li a.logo-prolyft:before {
          content: "\e93d";
          font-size: 40px;
          font-size: 2.5rem;
          transform: translateY(-4px); }
          @media (min-width: 768px) {
            .product-filter-items li a.logo-prolyft:before {
              font-size: 45px;
              font-size: 2.8125rem;
              transform: translateY(-6px); } }
        .product-filter-items li a.logo-prolyft.active:before {
          color: #8cbf30; }
      .product-filter-items li:last-of-type a {
        margin-right: 0px; }

.product-container {
  margin-top: 30px; }
  @media (min-width: 768px) {
    .product-container {
      margin-top: 0; } }
  .product-container .items-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-left: -15px;
    margin-right: -15px; }
    .product-container .items-container [class*="col-"] {
      padding-left: 7px;
      padding-right: 7px; }

.product-item {
  position: relative;
  display: block;
  padding: 5px;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  color: #000 !important;
  text-decoration: none !important;
  background-color: #e1e1e1;
  width: 100%;
  margin-bottom: 16px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: calc(100% - 16px);
  min-height: 162px; }
  .product-item:hover, .product-item:focus {
    text-decoration: none !important; }
    @media (min-width: 992px) {
      .product-item:hover, .product-item:focus {
        background-color: #f73c37; } }
    .product-item:hover .product-item-content:before, .product-item:focus .product-item-content:before {
      background-color: #000; }
    .product-item:hover .product-item-content:after, .product-item:focus .product-item-content:after {
      color: #f73c37; }
    @media (min-width: 992px) {
      .product-item:hover .product-item-content-button, .product-item:focus .product-item-content-button {
        color: #f73c37 !important;
        background: #000 !important;
        text-decoration: none !important; } }
    .product-item:hover .product-item-content-button:hover, .product-item:hover .product-item-content-button:focus, .product-item:focus .product-item-content-button:hover, .product-item:focus .product-item-content-button:focus {
      color: #fff !important; }
  .product-item-img {
    width: 100%;
    position: relative;
    text-align: center;
    -webkit-flex: 0 1 45%;
    -moz-flex: 0 1 45%;
    -ms-flex: 0 1 45%;
    -webkit-flex: 0 1 45%;
    flex: 0 1 45%;
    max-width: 45%;
    background-color: #fff; }
    .product-item-img img {
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .product-item-content {
    position: relative;
    -webkit-flex: 0 1 55%;
    -moz-flex: 0 1 55%;
    -ms-flex: 0 1 55%;
    -webkit-flex: 0 1 55%;
    flex: 0 1 55%;
    max-width: 55%;
    text-align: left;
    padding-left: 15px;
    padding-right: 20px; }
    .product-item-content-title {
      font-size: 18px;
      font-size: 1.125rem;
      border-bottom: 0px;
      text-decoration: none !important;
      line-height: 28px;
      background-image: linear-gradient(#858383 2px, transparent 2px);
      background-size: 100% 28px;
      background-position: 0 26px;
      font-family: "Zirkel-Black";
      font-weight: 800;
      padding-bottom: 2px;
      overflow: hidden;
      max-height: 56px; }
      @media (min-width: 480px) {
        .product-item-content-title {
          margin-top: 0px; } }
    .product-item-content-perex {
      line-height: 1.3;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: 400; }
    .product-item-content-button {
      padding: 4px 10px;
      margin-top: 15px;
      font-size: 14px;
      font-size: 0.875rem;
      background-color: #f73c37;
      color: #fff;
      border-color: #f73c37; }
      @media (min-width: 480px) {
        .product-item-content-button {
          position: absolute;
          bottom: 0;
          right: 0; } }
    .product-item-content:before {
      content: "";
      position: absolute;
      display: none;
      bottom: 2px;
      left: 17px;
      width: 27px;
      height: 27px;
      -webkit-border-radius: 27px;
      -moz-border-radius: 27px;
      -ms-border-radius: 27px;
      border-radius: 27px;
      background-color: transparent;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 992px) {
        .product-item-content:before {
          display: block; } }
    .product-item-content:after {
      content: "\e908";
      position: absolute;
      display: none;
      font-size: 30px;
      font-size: 1.875rem;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 480px) {
        .product-item-content:after {
          display: block;
          bottom: 0px;
          left: 5px; } }
      @media (min-width: 768px) {
        .product-item-content:after {
          bottom: 0px;
          left: 15px; } }
      @media (min-width: 545px) and (max-width: 991px) {
        .product-item-content:after {
          color: #000 !important; } }

.links-table {
  position: relative;
  border-top: 2px solid #fff;
  margin-top: 25px;
  padding-top: 10px; }
  @media (min-width: 992px) {
    .links-table {
      display: block;
      margin-top: 30px;
      padding-top: 10px; } }
  @media (min-width: 1200px) {
    .links-table {
      margin-top: 50px;
      padding-top: 30px; } }
  .links-table .row {
    width: 100%; }
  .links-table-item {
    height: 40px;
    position: relative;
    margin-top: 20px; }
    @media (min-width: 768px) {
      .links-table-item {
        height: 70px;
        flex: none;
        margin-top: 0; } }
    .links-table-item img {
      position: absolute;
      bottom: 50%;
      left: 0%;
      max-width: 100%;
      max-height: 100%;
      -ms-transform: translate(0%, 50%);
      -webkit-transform: translate(0%, 50%);
      transform: translate(0%, 50%); }
      @media (min-width: 992px) {
        .links-table-item img {
          bottom: 0;
          -ms-transform: translate(0%, 0);
          -webkit-transform: translate(0%, 0);
          transform: translate(0%, 0); } }
  .links-table.menu-links {
    margin-top: 20px;
    padding-top: 40px;
    padding-right: 10px;
    border-top: 0; }
    @media (min-width: 992px) {
      .links-table.menu-links {
        display: none; } }
    .links-table.menu-links:before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      width: calc(100% + 25px);
      height: 1px;
      background-color: #fff; }

.triangle-hover {
  position: relative;
  overflow: hidden; }
  .triangle-hover:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 50px solid #f73c37;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms;
    transform: rotate(-45deg) translate(82px, -14px);
    z-index: 88; }
  .triangle-hover:after {
    content: "\e91f";
    position: absolute;
    top: 9px;
    right: 8px;
    font-weight: 900;
    font-size: 26px;
    font-size: 1.625rem;
    color: #fff !important;
    opacity: 0;
    z-index: 99; }
  .triangle-hover:hover, .triangle-hover:focus {
    color: #000; }
    .triangle-hover:hover:before, .triangle-hover:focus:before {
      transform: rotate(-45deg) translate(31px, -18px); }
    .triangle-hover:hover:after, .triangle-hover:focus:after {
      animation: hover-arrow 200ms ease-out forwards;
      animation-delay: 350ms; }

@keyframes hover-arrow {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.brand-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  min-height: 335px;
  background-color: yellow;
  margin-top: 20px;
  position: relative;
  padding: 20px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.4; }
  @media (min-width: 768px) {
    .brand-item {
      margin-top: 35px;
      top: -190px; } }
  @media (min-width: 1200px) {
    .brand-item {
      margin-top: 55px;
      top: -210px; } }
  .brand-item-img {
    -webkit-flex: 0 1 100%;
    -moz-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    -webkit-flex: 0 1 100%;
    flex: 0 1 100%;
    max-width: 100%;
    padding-bottom: 40px; }
    @media (min-width: 768px) {
      .brand-item-img {
        -webkit-flex: 0 1 37.5%;
        -moz-flex: 0 1 37.5%;
        -ms-flex: 0 1 37.5%;
        -webkit-flex: 0 1 37.5%;
        flex: 0 1 37.5%;
        max-width: 37.5%;
        padding-bottom: 70px; } }
    .brand-item-img img {
      max-height: 150px;
      max-width: 100%; }
  .brand-item-content {
    -webkit-flex: 0 1 100%;
    -moz-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    -webkit-flex: 0 1 100%;
    flex: 0 1 100%;
    max-width: 100%;
    padding-bottom: 70px; }
    @media (min-width: 768px) {
      .brand-item-content {
        -webkit-flex: 0 1 62.5%;
        -moz-flex: 0 1 62.5%;
        -ms-flex: 0 1 62.5%;
        -webkit-flex: 0 1 62.5%;
        flex: 0 1 62.5%;
        max-width: 62.5%; } }
  .brand-item-bottom {
    -webkit-flex: 0 1 100%;
    -moz-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    -webkit-flex: 0 1 100%;
    flex: 0 1 100%;
    max-width: 100%;
    align-self: flex-end;
    color: #fff !important; }
    .brand-item-bottom-link {
      color: #fff !important;
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex; }
      .brand-item-bottom-link:after {
        content: "\e907";
        display: block;
        font-size: 32px;
        font-size: 2rem;
        color: #fff !important;
        font-weight: 600;
        margin-left: 10px;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .brand-item-bottom-link:hover:after, .brand-item-bottom-link:focus:after {
        -ms-transform: scale(0.8, 0.8);
        -webkit-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8); }
  .brand-item.theme-light {
    color: #fff; }
  .brand-item.theme-dark {
    color: #000; }

.header-menu {
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-size: 1.25rem;
  list-style: none;
  font-weight: bold; }
  @media (min-width: 768px) {
    .header-menu {
      font-size: 22px;
      font-size: 1.375rem; } }
  .header-menu.main {
    text-transform: uppercase; }
  .header-menu li {
    padding: 0;
    margin: 0;
    line-height: 1.3; }
    .header-menu li a {
      color: #fff;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .header-menu li a:hover, .header-menu li a:focus {
        color: #f73c37; }
  .header-menu.with-margin {
    margin-top: 15px; }

.content-gallery {
  margin: 40px 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row nowrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  position: relative; }
  .content-gallery-list {
    max-height: 100px;
    width: -webkit-calc(100% - 100px);
    width: -moz-calc(100% - 100px);
    width: calc(100% - 100px);
    overflow: hidden; }
    .content-gallery-list .slick-track {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
    .content-gallery-list-item {
      width: auto;
      height: 100px;
      display: block; }
      .content-gallery-list-item img {
        width: auto;
        height: 100px !important;
        margin-left: 50%;
        -ms-transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        margin-top: 0 !important;
        margin-bottom: 0 !important; }
  .content-gallery-arrow-left, .content-gallery-arrow-right {
    width: 40px;
    font-size: 0px;
    font-size: 0rem;
    cursor: pointer;
    margin-right: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none; }
    .content-gallery-arrow-left:before, .content-gallery-arrow-right:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: relative;
      top: 50%;
      left: 50%;
      display: block;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #000;
      font-size: 40px;
      font-size: 2.5rem;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #000;
      -moz-transition: all ease-in-out 150ms;
      -o-transition: all ease-in-out 150ms;
      -webkit-transition: all ease-in-out 150ms;
      transition: all ease-in-out 150ms;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none; }
    .content-gallery-arrow-left:focus:before, .content-gallery-arrow-left:hover:before, .content-gallery-arrow-right:focus:before, .content-gallery-arrow-right:hover:before {
      color: #fff;
      background-color: #000; }
  .content-gallery-arrow-right {
    margin-right: 0px;
    margin-left: 10px; }
    .content-gallery-arrow-right:before {
      content: "\e908"; }

.main-slider {
  width: 100%;
  position: relative; }
  .main-slider .slick-dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    text-align: center;
    padding: 0;
    margin: 0; }
    .main-slider .slick-dots li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px;
      width: 8px;
      height: 8px;
      cursor: pointer;
      background: #fff;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition-property: all;
      -moz-transition-property: all;
      -o-transition-property: all;
      transition-property: all;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease; }
      .main-slider .slick-dots li:hover {
        background: #231f20; }
      .main-slider .slick-dots li button {
        display: none; }
      .main-slider .slick-dots li.slick-active {
        background-color: #f73c37; }

.content-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important; }
  .content-item-title {
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
    text-align: center; }
    @media (min-width: 545px) {
      .content-item-title {
        font-size: 40px;
        line-height: 45px; } }
    @media (min-width: 768px) {
      .content-item-title {
        font-size: 55px;
        line-height: 60px; } }
    @media (min-width: 992px) {
      .content-item-title {
        font-size: 65px;
        line-height: 70px; } }
    @media (min-width: 1200px) {
      .content-item-title {
        font-size: 70px;
        line-height: 75px; } }
    @media (min-width: 1200px) {
      .content-item-title {
        font-size: 92px;
        line-height: 100px; } }
  .content-item-inner {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 300px;
    padding: 40px 30px; }
    @media (min-width: 545px) {
      .content-item-inner {
        height: 375px; } }
    @media (min-width: 768px) {
      .content-item-inner {
        height: 450px; } }
    @media (min-width: 992px) {
      .content-item-inner {
        height: 535px; } }

.banner-container {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.theme-text-black {
  color: #000; }

.theme-text-white {
  color: #fff; }

.embed-video.small {
  height: 420px; }

.embed-video.small-16 {
  height: 320px; }

.embed-video {
  height: 640px;
  position: relative; }

.embed-video.large-16 {
  height: 540px; }

.embed-video iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.embed-video-overflow.autoplay-1,
.embed-video-overflow.played {
  opacity: 0;
  visibility: hidden;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.embed-video-overflow {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #252525;
  background-size: cover;
  background-position: center;
  cursor: pointer; }
  .embed-video-overflow.in-popup {
    display: block; }

.embed-video-icon {
  display: block;
  height: 90px;
  width: 90px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.embed-video-icon:before {
  content: "";
  display: inline-block;
  font-size: 32px;
  width: 0;
  height: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid #fff;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  top: 22px;
  left: 35px;
  position: absolute; }

.submenu-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2px;
  right: 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer; }
  @media (min-width: 992px) {
    .submenu-btn {
      display: none !important; } }
  .submenu-btn:before {
    content: "\e91f";
    color: #fff;
    font-size: 24px;
    font-size: 1.5rem;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    font-weight: 800; }
  .submenu-btn.open:before {
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }

.afi-gallery {
  display: flex;
  flex-wrap: wrap; }
  .afi-gallery-item {
    padding-left: 1px;
    padding-bottom: 1px;
    flex: 0 1 100%;
    max-width: 100%; }
    @media (min-width: 480px) {
      .afi-gallery-item {
        flex: 0 1 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .afi-gallery-item {
        flex: 0 1 33.333%;
        max-width: 33.333%; } }
    .afi-gallery-item-inner {
      position: relative;
      overflow: hidden;
      display: block; }
      .afi-gallery-item-inner:before {
        content: "";
        display: block;
        padding-top: 80%; }
      .afi-gallery-item-inner img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center; }
  .afi-gallery.large-space .afi-gallery-item {
    padding-bottom: 40px; }

.contact-form {
  background-color: #e1e1e1; }
  .contact-form input[type=text],
  .contact-form textarea {
    margin-top: 20px;
    border: 0px;
    border-radius: 0;
    color: #000;
    padding: 13px 20px; }
  .contact-form input {
    min-height: 50px; }
  .contact-form textarea {
    min-height: 115px;
    resize: none; }
  .contact-form input[type=submit] {
    margin-top: 30px;
    background-color: #f73c37 !important;
    color: #fff;
    border: 0 !important;
    width: auto;
    min-height: auto;
    padding: 5px 15px;
    font-size: 14px;
    font-size: 0.875rem; }
    .contact-form input[type=submit]:hover, .contact-form input[type=submit]:focus {
      background-color: #f1100a !important; }

.contact-background {
  background-color: #e1e1e1; }

.person {
  margin-top: 44px; }
  .person-item {
    background: #e1e1e1;
    padding: 5px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column nowrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    @media (min-width: 545px) {
      .person-item {
        -moz-flex-flow: row nowrap;
        -ms-flex-direction: row;
        -ms-flex-wrap: nowrap;
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap; } }
    .person-item:not(:first-of-type) {
      margin-top: 28px; }
      @media (min-width: 768px) {
        .person-item:not(:first-of-type) {
          margin-top: 18px; } }
    .person-item-image {
      position: relative;
      width: 100%;
      background-color: #fff; }
      @media (min-width: 545px) {
        .person-item-image {
          -webkit-flex: 0 0 160px;
          -moz-flex: 0 0 160px;
          -ms-flex: 0 0 160px;
          -webkit-flex: 0 0 160px;
          flex: 0 0 160px;
          max-width: 160px;
          width: 160px;
          height: 100%; } }
      .person-item-image:before {
        display: block;
        content: "";
        padding-top: 80%; }
        @media (min-width: 545px) {
          .person-item-image:before {
            padding-top: 160px; } }
      .person-item-image img {
        max-width: 100%;
        max-height: 160px;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .person-item-content {
      padding: 15px 0;
      padding-bottom: 25px;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 1.3; }
      @media (min-width: 545px) {
        .person-item-content {
          padding: 0;
          padding-left: 15px; } }
      .person-item-content-name {
        padding-bottom: 12px; }
        @media (min-width: 545px) {
          .person-item-content-name {
            padding: 10px 0; } }
        .person-item-content-name-inner {
          font-size: 16px;
          font-size: 1rem;
          font-weight: 800;
          font-family: 'Zirkel-Black';
          display: inline-block;
          border-bottom: 1px solid #000; }

.contact-map {
  height: 300px; }
  @media (min-width: 768px) {
    .contact-map {
      height: 407px; } }

.office-item {
  padding: 10px;
  background: #e1e1e1;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: column nowrap;
  -ms-flex-direction: column;
  -ms-flex-wrap: nowrap;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap; }
  @media (min-width: 545px) {
    .office-item {
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap; } }
  .office-item:not(:first-of-type) {
    margin-top: 29px; }
    @media (min-width: 768px) {
      .office-item:not(:first-of-type) {
        margin-top: 26px; } }
  .office-item-map {
    position: relative;
    width: 100%;
    background-color: #fff;
    height: 200px; }
    @media (min-width: 545px) {
      .office-item-map {
        -webkit-flex: 0 0 200px;
        -moz-flex: 0 0 200px;
        -ms-flex: 0 0 200px;
        -webkit-flex: 0 0 200px;
        flex: 0 0 200px;
        max-width: 200px;
        width: 200px;
        min-height: auto; } }
  .office-item-content {
    padding: 20px 0;
    width: 100%; }
    @media (min-width: 545px) {
      .office-item-content {
        padding: 0;
        padding-left: 30px; } }
    .office-item-content-line {
      border-bottom: 1px solid #000;
      font-weight: 900;
      font-family: 'Zirkel-Black'; }
    .office-item-content-info {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: column nowrap;
      -ms-flex-direction: column;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: column nowrap;
      flex-flow: column nowrap;
      margin-top: 26px;
      line-height: 1.3; }
      @media (min-width: 768px) {
        .office-item-content-info {
          -moz-flex-flow: row nowrap;
          -ms-flex-direction: row;
          -ms-flex-wrap: nowrap;
          -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap; } }
      @media (min-width: 768px) {
        .office-item-content-info-address {
          -webkit-flex: 0 1 50%;
          -moz-flex: 0 1 50%;
          -ms-flex: 0 1 50%;
          -webkit-flex: 0 1 50%;
          flex: 0 1 50%; } }
      @media (min-width: 768px) {
        .office-item-content-info-contact {
          -webkit-flex: 0 1 50%;
          -moz-flex: 0 1 50%;
          -ms-flex: 0 1 50%;
          -webkit-flex: 0 1 50%;
          flex: 0 1 50%; } }

.map-points {
  display: none; }

.hp-category .EditorWidgets {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .hp-category .EditorWidgets .WebPart {
    display: block; }

@media print {
  header,
  footer,
  .box-header-content,
  .content-mobile-menu,
  .products-menu,
  .products-menu-generated,
  .newsletter-box,
  .square-button,
  .square-button-bw,
  .big-button-bw,
  .social-list,
  .big-button-bw-small,
  .content-button-level-up,
  .slick-arrow,
  .product-social-list {
    display: none !important; }
  .main-content {
    width: 100%; }
  .slick-list {
    overflow: visible !important; }
  .slick-track {
    width: 100% !important;
    transform: none !important; }
  .slick-slide {
    display: inline-block !important;
    float: none !important; }
  .content-gallery-list {
    width: 100% !important;
    max-height: none !important; }
  .content-tabs-list,
  .contact-card-content {
    display: block !important; }
  body {
    margin: 0;
    padding: 0;
    font-family: "Times New Roman", Times, serif;
    color: #423f3f !important; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif; }
  h1 {
    font-size: 16pt; }
  h2 {
    font-size: 14pt; }
  h3,
  h4,
  h5,
  h6 {
    font-size: 12pt; }
  p {
    line-height: 1.4;
    font-size: 12pt; }
  a:link,
  a:visited {
    text-decoration: underline;
    font-weight: bold;
    color: #000; }
  img {
    border: 1px solid #000; }
  ul * {
    display: block; } }

.fancybox-container * {
  outline: 0 !important; }

.fancybox-infobar__body {
  position: relative !important;
  max-width: -webkit-calc(100% - 132px);
  max-width: -moz-calc(100% - 132px);
  max-width: calc(100% - 132px);
  height: 44px !important;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block !important;
  background: transparent !important; }
  .fancybox-infobar__body .fancybox-caption {
    border: 0 !important;
    text-align: left;
    background: transparent !important;
    padding: 13px 10px;
    color: white; }

.fancybox-arrow {
  width: 40px !important;
  background-image: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important; }
  @media (min-width: 768px) {
    .fancybox-arrow {
      margin-left: 20px !important;
      margin-right: 20px !important; } }
  .fancybox-arrow:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: relative;
    top: 50%;
    left: 50%;
    display: inline-block !important;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    color: #fff !important;
    font-size: 40px;
    font-size: 2.5rem;
    width: 40px !important;
    height: 40px !important;
    border-radius: 40px !important;
    border: 1px solid #fff !important;
    background-color: rgba(0, 0, 0, 0.95) !important;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    z-index: 9999999; }
  .fancybox-arrow:after {
    background-color: transparent !important;
    background-image: none !important; }

.fancy-video.fancybox-is-open .fancybox-bg {
  opacity: 1 !important;
  background-color: #000 !important; }

.fancy-video .fancybox-toolbar,
.fancy-video .fancybox-navigation {
  height: 50px !important; }

.fancy-video .fancybox-infobar {
  top: auto !important;
  bottom: 0px !important;
  text-align: center !important;
  font-size: 16px;
  font-size: 1rem;
  margin-left: -50px !important;
  left: 50% !important; }

.fancy-video .fancybox-stage {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50px;
  bottom: 50px;
  left: 0;
  right: 0; }

.fancy-video .fancybox-content {
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important; }

.fancy-video .fancybox-arrow {
  height: auto !important;
  top: auto;
  bottom: 0% !important; }

.fancy-video .fancybox-button--close {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  top: 5px;
  right: 5px; }
  @media (min-width: 768px) {
    .fancy-video .fancybox-button--close {
      right: 20px; } }

.modal {
  background-color: rgba(0, 0, 0, 0.7);
  color: #000;
  padding-right: 0 !important; }
  .modal .modal-dialog {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 10px auto;
    width: 100%;
    max-width: 1250px; }
    @media (min-width: 768px) {
      .modal .modal-dialog {
        margin-top: 50px; } }
    @media (min-width: 1400px) {
      .modal .modal-dialog {
        margin-top: 100px; } }
    .modal .modal-dialog .modal-content {
      border-radius: 0;
      background-color: #e1e1e1; }
    .modal .modal-dialog .modal-header .modal-title {
      font-weight: 800;
      font-size: 20px;
      font-size: 1.25rem; }
      @media (min-width: 768px) {
        .modal .modal-dialog .modal-header .modal-title {
          font-size: 24px;
          font-size: 1.5rem; } }
      @media (min-width: 992px) {
        .modal .modal-dialog .modal-header .modal-title {
          font-size: 28px;
          font-size: 1.75rem; } }
    .modal .modal-dialog .modal-header .close {
      color: #f73c37;
      position: relative;
      font-size: 0;
      position: absolute;
      top: 15px;
      right: 15px;
      text-shadow: none; }
      .modal .modal-dialog .modal-header .close:after {
        content: "\e921";
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 15px;
        font-size: 0.9375rem;
        display: inline-block;
        color: #f73c37;
        -moz-transition: all ease-in-out 100ms;
        -o-transition: all ease-in-out 100ms;
        -webkit-transition: all ease-in-out 100ms;
        transition: all ease-in-out 100ms;
        cursor: pointer;
        color: #f73c37;
        font-weight: 800; }
      .modal .modal-dialog .modal-header .close:hover:after, .modal .modal-dialog .modal-header .close:focus:after {
        color: #f1100a; }
    .modal .modal-dialog .modal-body {
      text-align: left; }
      .modal .modal-dialog .modal-body .modal-perex {
        margin: 10px 0 30px;
        text-align: left;
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 18px;
        font-weight: 600; }
        @media (min-width: 545px) {
          .modal .modal-dialog .modal-body .modal-perex {
            margin-bottom: 30px; } }
    .modal .modal-dialog .modal-footer {
      display: block;
      margin-top: 10px; }
      .modal .modal-dialog .modal-footer .contact-submit-cont {
        width: 100%;
        margin: 0; }
        .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
          font-size: 12px;
          font-size: 0.75rem;
          color: #999;
          text-align: left;
          margin-bottom: 5px; }
          @media (min-width: 545px) {
            .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
              margin-bottom: 10px; } }
          @media (min-width: 768px) {
            .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
              font-size: 13px;
              font-size: 0.8125rem; } }
          @media (min-width: 992px) {
            .modal .modal-dialog .modal-footer .contact-submit-cont .contact-submit-text {
              margin-bottom: 18px; } }

.field-required:after {
  content: "*";
  color: #ff5445; }

.ErrorLabel,
.EditingFormErrorLabel {
  position: relative;
  display: block;
  width: 100%;
  margin: 20px 0px;
  padding: 5px 0;
  padding-left: 15px;
  background-color: #f73c37; }

.EditingFormErrorLabel {
  margin-top: 5px; }

#modal-languages .modal-content {
  padding: 20px;
  background-color: #000; }
  #modal-languages .modal-content .modal-header {
    background-color: #000; }
    #modal-languages .modal-content .modal-header .modal-title {
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 400;
      color: #fff; }
      @media (min-width: 768px) {
        #modal-languages .modal-content .modal-header .modal-title {
          font-size: 28px;
          font-size: 1.75rem; } }

#modal-languages .modal-language-country h3 {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 800; }
  @media (min-width: 992px) {
    #modal-languages .modal-language-country h3 {
      font-size: 18px;
      font-size: 1.125rem; } }

#modal-languages .modal-language-country h4 {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: normal; }
  #modal-languages .modal-language-country h4:last-of-type {
    text-transform: uppercase; }

#modal-languages .close {
  color: #fff; }
  @media (min-width: 320px) and (max-width: 767px) {
    #modal-languages .close {
      top: 10px;
      right: 30px; } }
  #modal-languages .close:after {
    color: #fff; }

.InfoLabel {
  display: block;
  margin: 30px 0; }

#get-quote .modal-content {
  max-width: 500px;
  width: 100%; }

#get-quote .modal-body {
  padding-top: 0px; }

#get-quote .modal-header {
  padding-bottom: 0 !important; }
