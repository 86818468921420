
@import "../_variables.scss";

@mixin align($direction:both, $position:0){
    position: absolute;

    @if $direction == both{
        left: $position;
        right: $position;
        margin: auto !important;
        bottom: $position;
        top: $position;
    }

    @if $direction == horizontal{
        left: $position;
        right: $position;
        margin-right: auto;
        margin-left: auto;
    }

    @if $direction == vertical {
        top: $position;
        bottom: $position;
        margin-top: auto;
        margin-bottom: auto;
    }
}

@mixin webkit{
    @media screen and (-webkit-min-device-pixel-ratio:0) { @content }
}

@function remove-units($number) {
    @return $number / ($number * 0 + 1);
}

@mixin eliminate-lines($height-line, $count-line) {
    display: block;
    height: remove-units($height-line) * remove-units($count-line) +px;
    line-height: remove-units($height-line) +px;
    overflow: hidden;
}

@mixin font-face($font-name, $font-file, $font-weight: normal, $font-style: normal) {

    @font-face {
        font-family: '#{$font-name}';
        src: url('#{$path-font}/#{$font-file}.eot');
        src: url('#{$path-font}/#{$font-file}.eot?#iefix') format('embedded-opentype'),
            url('#{$path-font}/#{$font-file}.woff2') format('woff2'),
            url('#{$path-font}/#{$font-file}.woff') format('woff'),
            url('#{$path-font}/#{$font-file}.ttf') format('truetype'),
            url('#{$path-font}/#{$font-file}.svg##{$font-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;

    }
    @include webkit {
        @font-face {
            font-family: '#{$font-name}';
            src: url('#{$path-font}/#{$font-file}.svg') format('svg');
        }
    }

}

@mixin font-face-otf($font-name, $font-file, $font-weight: normal, $font-style: normal) {

    @font-face {
        font-family: '#{$font-name}';
        src: url('#{$font-file}.otf'),
						 url('#{$font-file}.woff') format('woff');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}

@mixin font-face-woff($font-name, $font-file, $font-weight: normal, $font-style: normal) {

    @font-face {
        font-family: '#{$font-name}';
        src: url('#{$font-file}.woff') format('woff');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}


@mixin animate($anim:all, $duration:$transition-duration){
    @include transition-property($anim);
    @include transition-duration($duration);
    @include transition-function($transition-function);
}

@mixin transition-property($property:none) {
    -webkit-transition-property: #{$property};
       -moz-transition-property: #{$property};
         -o-transition-property: #{$property};
            transition-property: #{$property};
}

@mixin transition-duration($duration:$transition-duration) {
    -webkit-transition-duration: #{$duration};
       -moz-transition-duration: #{$duration};
         -o-transition-duration: #{$duration};
            transition-duration: #{$duration};
}

@mixin transition-function($function:$transition-function) {
    -webkit-transition-timing-function: #{$function};
       -moz-transition-timing-function: #{$function};
         -o-transition-timing-function: #{$function};
            transition-timing-function: #{$function};
}
