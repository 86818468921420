
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";



.contact-form {
		background-color: #e1e1e1;
		input[type=text],
		textarea {
				margin-top: 20px;
				border: 0px;
				border-radius: 0;
				color: $color-black;
				padding: 13px 20px;
		}
		input {
				min-height: 50px;
		}
		textarea {
				min-height: 115px;
				resize: none;
		}
		input[type=submit] {
				margin-top: 30px;
				background-color: $color-primary !important;
				color: $color-white;
				border: 0 !important;
				width: auto;
				min-height: auto;
				padding: 5px 15px;
				@include font-size(14px);
				&:hover,
				&:focus {
						background-color: darken($color-primary, 10) !important;
				}
		}
}

.contact-background {
		background-color: #e1e1e1;
}

.person {
		margin-top: 44px;
		&-item {
				background: $color-gray;
				padding: 5px;
				@include flex-block;
				@include flex-flow(column);
				@include media(sm) {
						@include flex-flow(row);
				}
				&:not(:first-of-type) {
				  	margin-top: 28px;
						@include media(md) {
								margin-top: 18px;
						}
				}
				&-image {
						position: relative;
						width: 100%;
						background-color: $color-white;
						@include media(sm) {
								@include flex(0 0 160px);
								max-width: 160px;
								width: 160px;
								height: 100%;
						}
						&:before {
								display: block;
								content: "";
								padding-top: 80%;
								@include media(sm) {
										padding-top: 160px;
								}
						}
						img {
								max-width: 100%;
								max-height: 160px;
								position: absolute;
								top: 50%;
								left: 50%;
								@include translate(-50%, -50%);
						}
				}
				&-content {
						padding: 15px 0;
						padding-bottom: 25px;
						@include font-size(14px);
						line-height: 1.3;
						@include media(sm) {
								padding: 0;
								padding-left: 15px;
						}
						&-name {
								padding-bottom: 12px;
								@include media(sm) {
										padding: 10px 0;
								}
						  	&-inner {
										@include font-size(16px);
										font-weight: 800;
										font-family: 'Zirkel-Black';
										display: inline-block;
						  	  	border-bottom: 1px solid $color-black;
						  	}
						}

				}
		}
}

.contact-map {
		height: 300px;
		@include media(md) {
				height: 407px;
		}
}

.office {
		&-item {
				padding: 10px;
				background: $color-gray;
				@include flex-block;
				@include flex-flow(column);
				@include media(sm) {
						@include flex-flow(row);
				}
				&:not(:first-of-type) {
						margin-top: 29px;
						@include media(md) {
								margin-top: 26px;
						}
				}
				&-map {
						position: relative;
						width: 100%;
						background-color: $color-white;
						height: 200px;
						@include media(sm) {
								@include flex(0 0 200px);
								max-width: 200px;
								width: 200px;
								min-height: auto;
						}
				}
				&-content {
						padding: 20px 0;
						width: 100%;
				  	@include media(sm) {
								padding: 0;
				  			padding-left: 30px;
				  	}
						&-line {
						  	border-bottom: 1px solid $color-black;
								font-weight: 900;
								font-family: 'Zirkel-Black';
						}
						&-info {
								@include flex-block;
								@include flex-flow(column);
								margin-top: 26px;
								line-height: 1.3;
								@include media(md) {
										@include flex-flow(row);
								}
								&-address {
										@include media(md) {
												@include flex(0 1 50%);
										}
								}
								&-contact {
										@include media(md) {
												@include flex(0 1 50%);
										}
								}
						}
				}
		}
}

.map-points {
    display: none;
}

.gm-style-iw {

}
