
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";

.hp-category{
    .EditorWidgets{
        width:100%;
        @include flex-block;
        .WebPart{
            display:block;
        }
    }
}

